import { UiAvatarProps, UiCell, UiCellProps } from '@vkph/ui';
import React, { FC, HTMLAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { UserAvatar } from '../../user-avatar';
import { UiUserInfoSkeleton } from './skeleton/UiUserInfoSkeleton';

export interface UiUserInfoProps
  extends Pick<HTMLAttributes<HTMLAnchorElement>, 'onClick'>,
    Omit<UiCellProps, 'LinkComponent'> {
  to: LinkProps['to'];
  isActive?: boolean;
  avatarProps?: UiAvatarProps;
}

interface UiUserInfoComposition extends FC<UiUserInfoProps> {
  Skeleton: typeof UiUserInfoSkeleton;
}

export const UiUserInfo: UiUserInfoComposition = (props) => {
  const { to, onClick, isActive, avatarProps, ...rest } = props;

  return (
    <UiCell
      titleProps={{ strong: true }}
      {...rest}
      linkComponent={(linkProps) => (
        <Link to={to} onClick={onClick}>
          {linkProps.children}
        </Link>
      )}
      avatar={<UserAvatar {...avatarProps} isActive={isActive} />}
    />
  );
};

UiUserInfo.Skeleton = UiUserInfoSkeleton;
