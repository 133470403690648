import { getModalStepsForSingleTitle, UiButton, UiForm, UiModal } from '@vkph/ui';
import React, { CSSProperties, FC, useMemo } from 'react';

import { getProfileListOptionsStorage, ProfileListParams } from '@vkph/common/store';
import { BaseUserProfileModel, UserCellValue } from '@vkph/common/types/models';
import { BaseDataType, OptionModel } from '@vkph/ui/types/option';

import { SearchOptionItem } from '../../../../search-option-complete';
import { SearchUserField } from '../../../../search-user-field';
import { DynamicTableCellContentProps } from '../../types';

type FormValues = {
  user: SearchOptionItem<OptionModel<string, BaseDataType & BaseUserProfileModel>>;
};

interface Props
  extends Pick<DynamicTableCellContentProps<UserCellValue | undefined>, 'value' | 'fieldOptions'> {
  onSave: (value: UserCellValue) => void;
  onModalClose?: () => void;
}

export const UserCellModalForm: FC<Props> = (props) => {
  const { onSave, value, onModalClose, fieldOptions } = props;
  const { organizationId, departmentId } = fieldOptions;
  const [form] = UiForm.useForm<FormValues>();
  const { storage: profileListOptionsStorage } = useMemo(getProfileListOptionsStorage, []);

  const formItemStyles: CSSProperties = { width: '100%', marginBottom: 0 };

  const initialValues = {
    user: value && { id: value.id, name: value.name, option: { avatar: value.avatar } },
  };

  const onSubmit = () => {
    form.submit();
    onModalClose?.();
  };

  const onFinish = ({ user }: FormValues) => {
    const val = {
      id: user.id,
      name: user.name,
      avatar: user.option?.data?.avatar,
    };

    onSave(val);
  };

  return (
    <UiForm form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Добавить пользователей')} />
      </UiModal.Header>
      <UiModal.Content basePadding>
        <UiForm.Item label="Выбрать пользователя" name="user" style={formItemStyles}>
          <SearchUserField<ProfileListParams>
            storage={profileListOptionsStorage}
            autoFetchParams={{ organizationRid: organizationId, divisionRid: departmentId }}
          />
        </UiForm.Item>
      </UiModal.Content>
      <UiModal.Footer>
        <UiModal.Footer.Buttons>
          <UiForm.Item noStyle shouldUpdate>
            {({ isFieldsTouched }) => (
              <UiButton type="primary" size="large" disabled={!isFieldsTouched()} onClick={onSubmit}>
                Сохранить
              </UiButton>
            )}
          </UiForm.Item>
          <UiButton type="tertiary" size="large" onClick={onModalClose}>
            Отмена
          </UiButton>
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </UiForm>
  );
};
