import {
  UiImageBackground,
  UiOverlay,
  UiList,
  UiButton,
  UiRow,
  UiEmpty,
  UiIcon,
  UiFlex,
  UiTooltip,
  UiOverlayTypes,
  UiImage,
} from '@vkph/ui';
import React, { CSSProperties, FC } from 'react';

import { BlogAlbumModel } from '@vkph/common/types/models';
import { useSpace } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';
import EditSvg from '@vkph/ui/svg/edit.svg';

import styles from './GalleryAlbumList.scss';
import { GalleryAlbumItem } from './gallery-album-list-item';

type Props = {
  albumList: BlogAlbumModel[];
  onSelectAlbum: (album: BlogAlbumModel) => void;
  onEditAlbum: (album: BlogAlbumModel) => void;
  toggleSettingsModal: () => void;
  isEditPermission?: boolean;
};

export const GalleryAlbumList: FC<Props> = (props) => {
  const { albumList, onSelectAlbum, onEditAlbum, toggleSettingsModal, isEditPermission } = props;
  const { spaceXS } = useSpace();

  const backgroundImageStyles: CSSProperties = {
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  if (!albumList.length) {
    return (
      <UiEmpty.Feed
        className={styles.galleryAlbumList__emptyText}
        bordered
        emptyMessage={{
          header: <UiRow padding={[0, 0, spaceXS]}>В группе еще нет ни одного альбома</UiRow>,
          description: isEditPermission && (
            <UiButton type="primary" onClick={toggleSettingsModal}>
              Создать альбом
            </UiButton>
          ),
        }}
      />
    );
  }

  return (
    <UiList
      className={styles.galleryAlbumList}
      grid={{ gutter: spaceXS, column: 3 }}
      size="default"
      dataSource={albumList}
      renderItem={(album) => (
        <UiList.Item onClick={() => onSelectAlbum(album)} className={styles.galleryAlbumList__item}>
          <UiOverlay
            style={{ borderRadius: variables.themeSizes.borderRadiusBase }}
            type={UiOverlayTypes.Tertiary}
            isFixedHeight
            content={<GalleryAlbumItem album={album} />}
          >
            {album.fileStorageCoverUrl && (
              <UiImageBackground style={backgroundImageStyles} url={album.fileStorageCoverUrl} />
            )}
            {!album.fileStorageCoverUrl && <UiImage height="100%" />}
          </UiOverlay>
          {isEditPermission && (
            <UiFlex className={styles.galleryAlbumList__actionButton}>
              <UiTooltip
                title="Редактировать альбом"
                getPopupContainer={(triggerNode) => triggerNode.parentElement || document.body}
              >
                <UiButton
                  type="action"
                  size="small"
                  icon={<UiIcon component={EditSvg} width={16} height={16} />}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onEditAlbum(album);
                  }}
                />
              </UiTooltip>
            </UiFlex>
          )}
        </UiList.Item>
      )}
    />
  );
};
