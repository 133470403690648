import {
  message,
  defaultUserMentionsProps,
  UiMention,
  UiMentionInputSearchProps,
  UiMentionsInput,
} from '@vkph/ui';
import React, { FC, KeyboardEvent, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { tagsDictsStorage, TagsFilters } from '@vkph/common/store/tags';
import { getErrorResponseMessage, generateMentionOptions } from '@vkph/common/utils';

export interface TagMentionSearchProps extends Omit<UiMentionInputSearchProps, 'children'> {
  onSearch?: (value: string) => void;
  filters?: Partial<TagsFilters>;
}

export const TagMentionSearch: FC<TagMentionSearchProps> = (props) => {
  const {
    value,
    placeholder = 'Поиск по содержанию или #тегу',
    wrapperStyle,
    onSearch,
    filters,
    ...restProps
  } = props;

  const handleKeyPress = useCallback(
    ({ key }: KeyboardEvent<HTMLElement>) => {
      if (key === 'Enter') {
        onSearch?.(value ?? '');
      }
    },
    [onSearch, value],
  );

  const onSearchMentionDebounced = useDebouncedCallback((query, callback) => {
    tagsDictsStorage.storage
      .fetchEffect({ value: query, ...filters })
      .then(({ results }) => generateMentionOptions(results, { valuePath: 'id', labelPath: 'name' }))
      .then(callback)
      .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось загрузить теги')));
  }, 300);

  return (
    <UiMentionsInput.Search
      onKeyDown={handleKeyPress}
      singleLine
      inputMode="search"
      value={value}
      placeholder={placeholder}
      wrapperStyle={wrapperStyle}
      {...restProps}
    >
      <UiMention
        {...defaultUserMentionsProps}
        trigger="#"
        markup="@[__display__](tag/__id__)"
        displayTransform={(_, display) => `#${display}`}
        data={onSearchMentionDebounced}
      />
    </UiMentionsInput.Search>
  );
};
