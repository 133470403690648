import {
  UiFile,
  UiFileDirection,
  UiFileProps,
  UiCol,
  UiRow,
  UiIcon,
  UiTruncateMarkup,
  UiTypography,
  UiButton,
  useBreakpoint,
  downloadFileByURL,
  useSpace,
  UiFlex,
} from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { FileStorageEntryType } from '@vkph/common/types/models';
import { getFullName, numberAbbreviator, getTotalSumByKey } from '@vkph/common/utils';
import { useToggle } from '@vkph/ui/hooks';
import CommentAltSvg from '@vkph/ui/svg/comment-alt.svg';
import DownloadSvg from '@vkph/ui/svg/download.svg';
import LikeSvg from '@vkph/ui/svg/like.svg';
import MeatballSvg from '@vkph/ui/svg/meatball.svg';
import ViewSvg from '@vkph/ui/svg/view.svg';

import { ActionsDropdown } from '../../../../actions-dropdown';
import { FileStorageListItemFileProps } from '../FileStorageListItemFile';
import { FileStorageListItemFileActions } from '../actions/FileStorageListItemFileActions';

type Props = Pick<FileStorageListItemFileProps, 'file' | 'getFileActions'> & UiFileProps;

export const FileStorageListItemFileRow: FC<Props> = (props) => {
  const { file, getFileActions, ...fileProps } = props;
  const { objectViewsCount, commentsCount, reactions, createdBy } = file;
  const reactionsCount = useMemo(() => getTotalSumByKey(reactions, 'reactionCount'), [reactions]);
  const author = createdBy ? getFullName(createdBy) : 'Автор неизвестен';
  const isFolder = file.type === FileStorageEntryType.Folder;
  const { lg: isLayoutLarge } = useBreakpoint();
  const [isMenuOpen, toggleMenuOpen] = useToggle([false, true]);
  const actions = getFileActions(file);
  const { spaceM } = useSpace();
  const fileUrl = file.file;

  return (
    <UiRow style={{ width: '100%' }} align="middle" gutter={[16, 0]}>
      <UiCol span={24} lg={10} xxl={10}>
        <UiFile direction={UiFileDirection.Horizontal} {...fileProps} />
      </UiCol>
      <UiCol span={21} lg={5}>
        <UiTypography.Text strong>
          <UiTruncateMarkup truncate tooltipProps={{ title: author }}>
            {author}
          </UiTruncateMarkup>
        </UiTypography.Text>
      </UiCol>
      {!isFolder && (
        <>
          <UiCol span={0} lg={2}>
            <UiIcon.Label label={numberAbbreviator(objectViewsCount)} component={ViewSvg} />
          </UiCol>
          <UiCol span={0} lg={2}>
            <UiIcon.Label component={LikeSvg}>{numberAbbreviator(reactionsCount)}</UiIcon.Label>
          </UiCol>
          <UiCol span={0} lg={2}>
            <UiIcon.Label component={CommentAltSvg}>{numberAbbreviator(commentsCount)}</UiIcon.Label>
          </UiCol>
        </>
      )}
      <UiFlex gap={spaceM} style={{ marginLeft: 'auto' }} wrap justify="flex-end">
        {!isLayoutLarge && (
          <UiButton
            style={{ order: 1 }}
            size="small"
            type="link-secondary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              toggleMenuOpen();
            }}
            icon={<UiIcon width={20} height={20} component={MeatballSvg} />}
          />
        )}
        {!isFolder && fileUrl && (
          <UiButton
            size="small"
            type="link-secondary"
            disabledFocus
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              downloadFileByURL(fileUrl);
            }}
            icon={<UiIcon component={DownloadSvg} width={20} height={20} />}
          />
        )}
        {isLayoutLarge && <ActionsDropdown items={actions} />}
      </UiFlex>
      {!isLayoutLarge && (
        <FileStorageListItemFileActions
          title={`Действия с ${isFolder ? 'папкой' : 'файлом'}`}
          actions={actions}
          open={isMenuOpen}
          onClose={() => toggleMenuOpen()}
        />
      )}
    </UiRow>
  );
};
