import { UiDivider, UiFileDirection, UiTypography } from '@vkph/ui';
import React, { FC, ReactNode, useMemo } from 'react';

import { FileStorageEntryType, FileStorageListEntryModel } from '@vkph/common/types/models';
import { declension, filesDeclension, getFormattedDate } from '@vkph/common/utils';
import { getFormattedFileSize } from '@vkph/ui/utils';

import { FileProps } from '../../../file';
import { FileStorageListItemInnerProps } from '../inner/FileStorageListItemInner';
import { FileStorageListItemFileColumn } from './column/FileStorageListItemFileColumn';
import { FileStorageListItemFileRow } from './row/FileStorageListItemFileRow';

export interface FileStorageListItemFileProps
  extends Pick<FileProps, 'direction'>,
    Pick<FileStorageListItemInnerProps, 'getFileActions'> {
  file: FileStorageListEntryModel;
}

export const FileStorageListItemFile: FC<FileStorageListItemFileProps> = (props) => {
  const { direction = UiFileDirection.Horizontal, file, ...restProps } = props;
  const { size: fileSize, createdAt, name } = file;
  const isFolder = file.type === FileStorageEntryType.Folder;

  const subtitle = useMemo<ReactNode>(() => {
    return (
      <UiTypography.Text type="secondary">
        {isFolder && `${file.filesCount} ${declension(file.filesCount, filesDeclension)}`}
        {!isFolder && (
          <>
            {getFormattedFileSize(fileSize)}
            <UiDivider.Dot type="secondary" />
            {createdAt ? getFormattedDate(createdAt, 'date') : ''}
          </>
        )}
      </UiTypography.Text>
    );
  }, [fileSize, file, createdAt]);

  const FileListDirectionItem =
    direction === UiFileDirection.Horizontal ? FileStorageListItemFileRow : FileStorageListItemFileColumn;

  return (
    <FileListDirectionItem
      {...restProps}
      title={name}
      subtitle={subtitle}
      fileName={name}
      file={file}
      isFolder={isFolder}
    />
  );
};
