import {
  BadgeColors,
  UiBadge,
  UiFlex,
  UiTag,
  UiTypography,
  usePaddingStyle,
  useSpace,
  useTheme,
} from '@vkph/ui';
import React from 'react';

import { BlogAlbumModel } from '@vkph/common/types/models';

type Props = {
  album: BlogAlbumModel;
};

export const GalleryAlbumItem = (props: Props) => {
  const { album } = props;
  const { category } = album;
  const { markerColor, name } = category || {};
  const { space2XS, spaceXS, spaceS } = useSpace();
  const paddingStyle = usePaddingStyle([spaceXS, spaceS]);
  const [{ variables: themeVariables }] = useTheme();

  return (
    <UiFlex vertical justify={category ? 'space-between' : 'flex-end'} style={{ height: '100%' }}>
      {category && (
        <UiFlex style={{ padding: space2XS }}>
          <UiTag shift={0} title={name} color={markerColor} />
        </UiFlex>
      )}
      <UiFlex gap={spaceS} justify="space-between" style={paddingStyle}>
        <UiTypography.Title level={5} style={{ color: themeVariables.colorTextOnBrand }}>
          {album.title}
        </UiTypography.Title>
        <UiFlex>
          <UiBadge color={BadgeColors.Primary} count={album.imagesCount} />
        </UiFlex>
      </UiFlex>
    </UiFlex>
  );
};
