import React, { FC, RefObject, useEffect } from 'react';

import { defaultValueCell, GetListRowsStorage } from '@vkph/common/store/lists';
import {
  DictionaryCellValue,
  FileCellValue,
  ListCellValues,
  ListColumnFieldType,
  ListColumnModel,
  ListRowId,
  ListRowValues,
  UserCellValue,
} from '@vkph/common/types/models';
import { UiButton, UiDropdown, UiForm, UiIcon, UiMenuItemTypes } from '@vkph/ui';
import ClearSvg from '@vkph/ui/svg/clear-filled.svg';
import MeatballSvg from '@vkph/ui/svg/meatball.svg';

import {
  ColumnDropdownItemsMap,
  DynamicTableRow,
  OnUpdateRowParams,
  RowDropdownItemsMap,
} from '../DynamicTable';
import { DYNAMIC_TABLE_CELL_DATA_PREFIX, DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX } from '../constants';
import { normalizeValueCellToForm } from '../normalize';
import { BooleanCell } from './boolean/BooleanCell';
import { DYNAMIC_TABLE_CELL_COMMON_RULE_BOOK } from './constants';
import { DateCell } from './date/DateCell';
import { DictionaryCell } from './dictionary/DictionaryCell';
import { EnumCell } from './enum/EnumCell';
import { FileCell } from './file/FileCell';
import { GroupCell } from './group/GroupCell';
import { LinkCell } from './link/LinkCell';
import { NumericCell } from './number/NumericCell';
import { TextCell } from './text/TextCell';
import { DynamicTableCellContentProps } from './types';
import { UserCell } from './user/UserCell';
import { isCellOfType } from './utils';

export interface ListRowValuesWithId extends ListRowValues {
  id: ListRowId;
}

export type DynamicTableCellProps = {
  record: DynamicTableRow;
  onUpdate: (params: OnUpdateRowParams) => Promise<void>;
  columnDropdownItemsMap: ColumnDropdownItemsMap;
  rowDropdownItemsMap: RowDropdownItemsMap;
  column: ListColumnModel;
  containerRef?: RefObject<HTMLDivElement>;
  readOnly?: boolean;
  rowStorage: GetListRowsStorage['storage'];
  // TODO: Убрать передачу toggleIsBlockedTable после https://jira.vk.team/browse/B2BCORE-11050
  toggleIsBlockedTable?: (value: boolean) => void;
};

export const DynamicTableCell: FC<DynamicTableCellProps> = (props) => {
  const {
    record,
    containerRef,
    onUpdate,
    column,
    readOnly,
    rowStorage,
    toggleIsBlockedTable,
    columnDropdownItemsMap,
    rowDropdownItemsMap,
  } = props;

  const { id: dataIndex, fieldType, fieldOptions, isRequired } = column;
  const { id: rowId, group, ...rowValues } = record;
  const isVirtual = rowId.includes(DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX);
  const form = UiForm.useFormInstance();
  const cellNamePath = [DYNAMIC_TABLE_CELL_DATA_PREFIX, rowId, dataIndex];
  const recordValue = record[dataIndex];

  useEffect(() => {
    if (isVirtual) {
      form.validateFields([cellNamePath]);
    }
  }, [isVirtual, cellNamePath]);

  const onClearCell = () => {
    const value = fieldOptions?.default
      ? normalizeValueCellToForm(fieldOptions.default, fieldType)
      : defaultValueCell[fieldType];

    form.setFieldValue(cellNamePath, value);

    const data = {
      ...rowValues,
      [dataIndex]: value,
    };

    const { children, key, ...restData } = data;

    onUpdate({ rowId, rowValues: restData });
  };

  const onFinish = (values: ListRowValues) => {
    const valueForm = values[dataIndex];

    if (valueForm === recordValue) {
      return null;
    }

    const data = {
      ...rowValues,
      [dataIndex]: valueForm,
    };

    const { children, key, ...restData } = data;

    return onUpdate({ rowId, rowValues: restData });
  };

  const onCellBlur = (newValue: ListCellValues) => {
    if (newValue === recordValue) {
      return null;
    }

    const values = form.getFieldValue([DYNAMIC_TABLE_CELL_DATA_PREFIX, rowId]);

    const data = {
      ...values,
      [dataIndex]: newValue,
    };

    const { children, key, ...restData } = data;

    return onUpdate({ rowId, rowValues: restData });
  };

  const cellMenuItems: UiMenuItemTypes[] = [
    {
      key: 'clear',
      label: 'Очистить ячейку',
      icon: <UiIcon width={20} height={20} component={ClearSvg} />,
      onClick: () => onClearCell(),
    },
  ];

  const { duplicateColumn, addColumn, deleteColumn } = columnDropdownItemsMap;
  const { duplicateRow, addRow, deleteRow, groupRowByCell } = rowDropdownItemsMap;

  const extraMenuItems: UiMenuItemTypes[] = [
    duplicateColumn,
    duplicateRow,
    addColumn,
    addRow,
    ...(isVirtual ? [] : [groupRowByCell]),
    deleteColumn,
    deleteRow,
  ];

  const cellInputSuffix = (
    <UiDropdown
      trigger={['click']}
      placement="bottomRight"
      getPopupContainer={() => containerRef?.current || document.body}
      menu={{ items: cellMenuItems.concat(extraMenuItems) }}
    >
      <UiButton
        type="link-secondary"
        icon={<UiIcon width={20} height={20} component={MeatballSvg} />}
        onClick={(e) => e.stopPropagation()}
      />
    </UiDropdown>
  );

  const cellProps: DynamicTableCellContentProps = {
    readOnly,
    containerRef,
    onFinish,
    onCellBlur,
    value: recordValue,
    cellNamePath,
    dataIndex,
    fieldType,
    fieldOptions,
    rules: isRequired ? DYNAMIC_TABLE_CELL_COMMON_RULE_BOOK[fieldType] : undefined,
    inputSuffix: readOnly ? null : cellInputSuffix,
  };

  if (group) {
    return (
      <GroupCell
        {...cellProps}
        group={group}
        rowStorage={rowStorage}
        toggleIsBlockedTable={toggleIsBlockedTable}
      />
    );
  }

  return (
    <>
      {isCellOfType<number>(cellProps, ListColumnFieldType.Numeric) && <NumericCell {...cellProps} />}
      {isCellOfType<string>(cellProps, ListColumnFieldType.Text) && <TextCell {...cellProps} />}
      {isCellOfType<boolean>(cellProps, ListColumnFieldType.Boolean) && <BooleanCell {...cellProps} />}
      {isCellOfType<string>(cellProps, ListColumnFieldType.Hyperlink) && <LinkCell {...cellProps} />}
      {isCellOfType<DictionaryCellValue[]>(cellProps, ListColumnFieldType.Dictionary) && (
        <DictionaryCell {...cellProps} />
      )}
      {isCellOfType<string[]>(cellProps, ListColumnFieldType.Enum) && <EnumCell {...cellProps} />}
      {isCellOfType<UserCellValue>(cellProps, ListColumnFieldType.User) && <UserCell {...cellProps} />}
      {isCellOfType<string>(cellProps, ListColumnFieldType.Datetime) && <DateCell {...cellProps} />}
      {isCellOfType<FileCellValue>(cellProps, ListColumnFieldType.File) && <FileCell {...cellProps} />}
    </>
  );
};
