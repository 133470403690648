import { ToolbarConfigItem } from '@ckeditor/ckeditor5-core';

export enum EditorToolbarItem {
  SelectAll = 'selectAll',
  ImageUpload = 'uploadImageCrop',
  VideoUpload = 'videoUpload',
  SimpleFileUpload = 'simpleFileUpload',
  Heading = 'heading',
  Bold = 'bold',
  Italic = 'italic',
  StrikeThrough = 'strikethrough',
  Underline = 'underline',
  Code = 'code',
  Subscript = 'subscript',
  Superscript = 'superscript',
  BulletedList = 'bulletedList',
  NumberedList = 'numberedList',
  Outdent = 'outdent',
  Indent = 'indent',
  Undo = 'undo',
  Redo = 'redo',
  FontSize = 'fontSize',
  FontColor = 'fontColor',
  FontBackgroundColor = 'fontBackgroundColor',
  Highlight = 'highlight',
  Alignment = 'alignment',
  Link = 'link',
  BlockQuote = 'blockQuote',
  CodeBlock = 'codeBlock',
  InsertTable = 'insertTable',
  SpecialCharacters = 'specialCharacters',
  MediaEmbed = 'mediaEmbed',
}

export const editorMainToolbarItems: ToolbarConfigItem[] = [
  EditorToolbarItem.SelectAll,
  EditorToolbarItem.ImageUpload,
  EditorToolbarItem.VideoUpload,
  EditorToolbarItem.SimpleFileUpload,
];

export const editorHeadingToolbarItems: ToolbarConfigItem[] = [EditorToolbarItem.Heading];

export const editorTextToolbarItems: ToolbarConfigItem[] = [
  EditorToolbarItem.FontSize,
  EditorToolbarItem.Bold,
  EditorToolbarItem.Italic,
  EditorToolbarItem.StrikeThrough,
  EditorToolbarItem.Underline,
  EditorToolbarItem.Code,
  EditorToolbarItem.Subscript,
  EditorToolbarItem.Superscript,
];

export const editorListToolbarItems: ToolbarConfigItem[] = [
  EditorToolbarItem.BulletedList,
  EditorToolbarItem.NumberedList,
  EditorToolbarItem.Outdent,
  EditorToolbarItem.Indent,
  EditorToolbarItem.Undo,
  EditorToolbarItem.Redo,
];

export const editorColorToolbarItems: ToolbarConfigItem[] = [
  EditorToolbarItem.FontColor,
  EditorToolbarItem.FontBackgroundColor,
  EditorToolbarItem.Highlight,
];

export const editorAlignmentToolbarItems: ToolbarConfigItem[] = [EditorToolbarItem.Alignment];

export const editorSpecialToolbarItems: ToolbarConfigItem[] = [
  EditorToolbarItem.Link,
  EditorToolbarItem.BlockQuote,
  EditorToolbarItem.CodeBlock,
  EditorToolbarItem.InsertTable,
  EditorToolbarItem.SpecialCharacters,
  EditorToolbarItem.MediaEmbed,
];

export const allEditorToolbars: ToolbarConfigItem[][] = [
  editorMainToolbarItems,
  editorHeadingToolbarItems,
  editorTextToolbarItems,
  editorListToolbarItems,
  editorColorToolbarItems,
  editorAlignmentToolbarItems,
  editorSpecialToolbarItems,
];
