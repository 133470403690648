import { defaultValueCell } from '@vkph/common/store/lists';
import { CreateListRowParams, UpdateListRowParams } from '@vkph/common/store/lists/api';
import {
  FileCellValue,
  ListCellFileModel,
  DictionaryCellModelValue,
  DictionaryCellValue,
  ListCellModelValues,
  ListCellValues,
  ListColumnFieldType,
  ListColumnModel,
  ListRowModelValues,
  ListRowValues,
} from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic } from '@vkph/common/utils';

import { isBooleanCellType, isFileType, isDictionaryType, isUserCellType, isUserModelType } from './guard';

const normalizeCellValues = <RowValues extends object, CellValue, Response>(
  rowValues: RowValues,
  columnsData: ListColumnModel[],
  callback: (cellValue: CellValue, type: ListColumnFieldType) => unknown,
) => {
  const columnTypes = new Map();

  columnsData.forEach((column) => {
    columnTypes.set(column.id, column.fieldType);
  });

  return Object.fromEntries(
    Object.entries(rowValues).map(([key, cellValue]) => {
      return [key, callback(cellValue, columnTypes.get(key))];
    }),
  ) as Response;
};

export const normalizeValueCellToForm = (
  value: ListCellModelValues,
  fieldType: ListColumnFieldType,
): ListCellValues => {
  if (isUserModelType(value, fieldType)) {
    return {
      id: value.keycloakId || value.id,
      name: getFullNameWithoutPatronymic(value),
      avatar: value.smallAvatar,
    };
  }

  if (isDictionaryType<DictionaryCellModelValue[]>(value, fieldType)) {
    return value.map(({ dictionaryValueId, dictionaryValue, dictionaryType }) => ({
      label: dictionaryValue,
      value: dictionaryValueId,
      type: dictionaryType,
    }));
  }

  if (isBooleanCellType(value, fieldType)) {
    return value[0];
  }

  if (isFileType<ListCellFileModel>(value, fieldType)) {
    const { file, fileData, name, id } = value;

    return {
      file,
      name,
      id,
      size: fileData?.size,
      createdAt: fileData?.createdAt,
    };
  }

  return value;
};

export const normalizeRowValuesToForm = (
  rowValues: ListRowModelValues,
  columnsData: ListColumnModel[],
): ListRowValues => {
  return normalizeCellValues<ListRowModelValues, ListCellModelValues, ListRowValues>(
    rowValues,
    columnsData,
    normalizeValueCellToForm,
  );
};

const normalizeValueCellToSave = (value: ListCellValues, fieldType: ListColumnFieldType) => {
  if (fieldType === ListColumnFieldType.Numeric) {
    return Number(value);
  }

  if (isUserCellType(value, fieldType)) {
    return value.id;
  }

  if (isDictionaryType<DictionaryCellValue[]>(value, fieldType)) {
    return value.length === 0
      ? defaultValueCell[fieldType]
      : {
          dictionaryType: value[0].type,
          dictionaryValuesIds: value.map((item) => item.value),
        };
  }

  if (isFileType<FileCellValue>(value, fieldType)) {
    return value.id;
  }

  return value;
};

type CreateOrUpdateRowValues = UpdateListRowParams['rowValues'] | CreateListRowParams['rowValues'];

export const normalizeRowValuesToSave = (
  rowValues: ListRowValues,
  columnsData: ListColumnModel[],
): CreateOrUpdateRowValues => {
  return normalizeCellValues<ListRowValues, ListCellValues, CreateOrUpdateRowValues>(
    rowValues,
    columnsData,
    normalizeValueCellToSave,
  );
};
