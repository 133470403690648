import {
  UiFormItemProps,
  UiMultiSelect,
  UiMultiSelectItemId,
  UiMultiSelectItemType,
  UiMultiSelectProps,
} from '@vkph/ui';
import React from 'react';

import { BaseDataType, OptionModel } from '@vkph/ui/types/option';

export interface MultiSelectFieldProps<
  ValueType extends UiMultiSelectItemId = UiMultiSelectItemId,
  DataType extends BaseDataType = BaseDataType,
> extends Omit<UiMultiSelectProps, 'items' | 'ItemContent' | 'value' | 'onChange'>,
    UiFormItemProps<OptionModel<ValueType, DataType>[]> {
  options: OptionModel<ValueType, DataType>[];
  ItemContent?: (props: Omit<UiMultiSelectItemType<ValueType, DataType>, 'ItemContent'>) => JSX.Element;
}

export const MultiSelectField = <
  ValueType extends UiMultiSelectItemId = UiMultiSelectItemId,
  DataType extends BaseDataType = BaseDataType,
>(
  props: MultiSelectFieldProps<ValueType, DataType>,
) => {
  const { value = [], onChange, ...rest } = props;

  const onItemAdd = (option: OptionModel<ValueType, DataType>) => {
    onChange?.([...value, option]);
  };

  const onItemRemove = (optionValue: OptionModel<ValueType, DataType>['value']) => {
    onChange?.(value.filter((option: OptionModel) => option.value !== optionValue));
  };

  return (
    <UiMultiSelect<ValueType, DataType>
      items={value}
      onItemAdd={onItemAdd}
      onItemRemove={onItemRemove}
      {...rest}
    />
  );
};
