import React, { CSSProperties, FC, useEffect } from 'react';

import { getFormattedDate } from '@vkph/common/utils';
import { UiDatePicker, UiFlex, UiForm, UiTimePicker, useModalBase, useSpace } from '@vkph/ui';

import { CellInput } from '../../../cell-input';
import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<string>;

export const DateCell: FC<Props> = (props) => {
  const {
    inputSuffix,
    fieldOptions: { onlyDate },
    value,
    cellNamePath,
    readOnly,
    onCellBlur,
    rules,
  } = props;
  const { confirm } = useModalBase();
  const form = UiForm.useFormInstance();
  const date = UiForm.useWatch<string>(cellNamePath, form);
  const [localForm] = UiForm.useForm();
  const { spaceM } = useSpace();

  const formItemStyles: CSSProperties = { width: '100%', marginBottom: 0 };
  const datePickerStyles: CSSProperties = { width: '100%' };

  const initialValues = {
    date: value ? new Date(value) : '',
  };

  const openModal = () => {
    confirm({
      title: 'Выбрать дату',
      okText: 'Сохранить',
      cancelText: 'Отменить',
      content: (
        <UiForm
          form={localForm}
          onFinish={(values) => {
            form.setFieldValue(cellNamePath, values.date);
            onCellBlur?.(values.date);
          }}
          initialValues={initialValues}
        >
          <UiFlex gap={spaceM}>
            <UiForm.Item name="date" style={formItemStyles}>
              <UiDatePicker style={datePickerStyles} size="large" />
            </UiForm.Item>
            {!onlyDate && (
              <UiForm.Item name="date" style={formItemStyles}>
                <UiTimePicker style={datePickerStyles} size="large" />
              </UiForm.Item>
            )}
          </UiFlex>
        </UiForm>
      ),
      onOk: () => {
        localForm.submit();
      },
      onCancel: () => {
        localForm.setFieldValue('date', value ? new Date(value) : '');
      },
    });
  };

  useEffect(() => {
    form.setFieldValue(cellNamePath, value ? new Date(value) : '');
  }, [value]);

  return (
    <UiForm.Item name={cellNamePath} rules={rules}>
      <UiFlex style={{ position: 'relative' }}>
        <CellInput.Button
          onClick={readOnly ? undefined : openModal}
          isEmpty={!date}
          suffix={inputSuffix}
          readOnly={readOnly}
        >
          {date ? getFormattedDate(date, onlyDate ? 'date' : 'dateTime') : 'Добавить дату'}
        </CellInput.Button>
      </UiFlex>
    </UiForm.Item>
  );
};
