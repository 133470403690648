import {
  UiCheckbox,
  UiCheckboxMenuProps,
  UiFlex,
  UiForm,
  usePaddingStyle,
  useSpace,
  useTheme,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import uniqBy from 'lodash/uniqBy';
import React, { FC, RefObject } from 'react';

import { GetListRowsStorage } from '@vkph/common/store/lists';
import {
  DictionaryCellModelValue,
  ListCellModelValues,
  ListColumnFieldType,
  ListColumnModel,
  ListCellFileModel,
  ListRowModel,
  UserModelIdRequired,
} from '@vkph/common/types/models';
import { getFormattedDate, getFullNameWithoutPatronymic } from '@vkph/common/utils';
import TableFilterSVG from '@vkph/ui/svg/table-filter.svg';

type FormValues = {
  filters?: string[];
};

type Props = {
  column: ListColumnModel;
  listRowsStorage: GetListRowsStorage;
  containerRef?: RefObject<HTMLDivElement>;
};

// @TODO переделать на boolean типы после выполнения бек задачи - https://jira.vk.team/browse/B2BCORE-13269
// сделать ветку B2BCORE-10231_2  - часть 2
enum ValuesFilterBoolean {
  False = 'false',
  True = 'true',
}

const isSupportFilterValue = (value: ListCellModelValues): value is string | number =>
  typeof value === 'string' || typeof value === 'number';

const isDateType = (fieldType: ListColumnFieldType, value: ListCellModelValues): value is string =>
  fieldType === ListColumnFieldType.Datetime && typeof value === 'string';

const checkHasFilters = (filters?: string[]) => Array.isArray(filters) && filters.length > 0;

const isFileResponseType = (
  fieldType: ListColumnFieldType,
  value: ListCellModelValues,
): value is ListCellFileModel => {
  return (
    fieldType === ListColumnFieldType.File && typeof value === 'object' && 'id' in value && 'name' in value
  );
};

const isDictionaryType = (
  fieldType: ListColumnFieldType,
  value: ListCellModelValues,
): value is DictionaryCellModelValue[] =>
  fieldType === ListColumnFieldType.Dictionary && Array.isArray(value);

const isEnumType = (fieldType: ListColumnFieldType, value: ListCellModelValues): value is string[] => {
  return fieldType === ListColumnFieldType.Enum && Array.isArray(value);
};

const isUserType = (
  fieldType: ListColumnFieldType,
  value: ListCellModelValues,
): value is UserModelIdRequired =>
  fieldType === ListColumnFieldType.User && typeof value === 'object' && 'keycloakId' in value;

const normalizeFilterValues = (listRows: ListRowModel[], column: ListColumnModel) => {
  const { id, fieldType, fieldOptions } = column;

  const options: UiCheckboxMenuProps['items'] = [];

  listRows.forEach((row) => {
    const value = row.rowValues[id] || column.fieldOptions.default;

    if (isDictionaryType(fieldType, value)) {
      options.push(
        ...value.map((item) => {
          return {
            value: item.dictionaryValueId,
            label: item.dictionaryValue,
          };
        }),
      );
    }

    if (fieldType === ListColumnFieldType.Boolean) {
      options.push(
        {
          value: ValuesFilterBoolean.True,
          label: column.fieldOptions.trueValueName,
        },
        {
          value: ValuesFilterBoolean.False,
          label: column.fieldOptions.falseValueName,
        },
      );
    }

    if (isFileResponseType(fieldType, value)) {
      options.push({
        value: value.id,
        label: value.name,
      });
    }

    if (isUserType(fieldType, value)) {
      options.push({
        value: value.keycloakId,
        label: getFullNameWithoutPatronymic(value),
      });
    }

    if (isDateType(fieldType, value)) {
      const dateValue = getFormattedDate(value, fieldOptions.onlyDate ? 'date' : 'dateTime');

      options.push({
        value,
        label: dateValue,
      });
    }

    if (isEnumType(fieldType, value)) {
      options.push(
        ...value.map((item) => ({
          value: item,
          label: item,
        })),
      );
    }

    if (isSupportFilterValue(value)) {
      options.push({
        value,
        label: value,
      });
    }
  });

  return uniqBy(options, 'value');
};

export const DynamicTableFilters: FC<Props> = (props) => {
  const { listRowsStorage, containerRef, column } = props;
  const { id } = column;
  const { spaceXS, spaceXL, spaceS } = useSpace();
  const [form] = UiForm.useForm<FormValues>();
  const [{ variables: themeVariables }] = useTheme();
  const styles = usePaddingStyle([spaceS, 0]);
  const { padding } = usePaddingStyle([spaceXL, spaceXL, 0, spaceXL]);

  const { data: listRowsData } = useStore(listRowsStorage.storage.store);
  const { updateStoreEvent: updateRowsParamsEvent, store: rowsParamsStore } = listRowsStorage.paramsStore;

  const listRowsParams = useStore(rowsParamsStore);

  const onFinish = (values: FormValues) => {
    const { filters } = values;

    updateRowsParamsEvent({
      ...listRowsParams,
      filters: {
        ...listRowsParams.filters,
        [id]: checkHasFilters(filters) ? filters : undefined,
      },
    });
  };

  return (
    <UiForm form={form} onFinish={onFinish}>
      <UiFlex gap={spaceXS}>
        <UiForm.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <UiForm.Item name="filters" noStyle>
              <UiCheckbox.Menu
                icon={TableFilterSVG}
                getPopupContainer={() => containerRef?.current || document.body}
                overlayInnerStyle={styles}
                placement="bottomLeft"
                align={{ offset: [-spaceXS, -spaceXS] }}
                contentContainerGap={spaceS}
                isActiveRow
                buttonProps={{
                  disabledFocus: false,
                }}
                iconProps={{
                  color: checkHasFilters(getFieldValue('filters'))
                    ? themeVariables.colorBrand
                    : themeVariables.colorIcon,
                }}
                actionsStyles={{ padding, borderTop: themeVariables.spacerBorder }}
                actions={[
                  {
                    type: 'primary',
                    size: 'small',
                    label: 'Применить',
                    onClick: () => {
                      form.submit();
                    },
                  },
                  {
                    type: 'tertiary',
                    size: 'small',
                    label: 'Сбросить',
                    onClick: () => {
                      form.resetFields();
                      form.submit();
                    },
                  },
                ]}
                items={normalizeFilterValues(listRowsData, column)}
              />
            </UiForm.Item>
          )}
        </UiForm.Item>
      </UiFlex>
    </UiForm>
  );
};
