import classNames from 'classnames';
import { useStore } from 'effector-react';
import merge from 'lodash/merge';
import partition from 'lodash/partition';
import React, { FC, Key, ReactNode, useCallback, useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  closeGlobalModal,
  GlobalModalNames,
  GlobalModalsStorePayloads,
  openGlobalModal,
} from '@vkph/common/store';
import {
  defaultValueCell,
  getListColumnsStorage,
  GetListRowsParams,
  getListRowsStorage,
  getListVirtualRowStorage,
} from '@vkph/common/store/lists';
import {
  ListColumnFieldType,
  ListColumnId,
  ListColumnModel,
  ListGroup,
  ListModel,
  ListRowGroupType,
  ListRowId,
  ListRowModel,
  ListRowValues,
} from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import {
  ColumnsType,
  generateRandomString,
  notification,
  UiButton,
  UiDropdown,
  UiDropdownProps,
  UiFlex,
  UiForm,
  UiIcon,
  UiMenuItemTypes,
  UiRender,
  UiRenderType,
  UiSpace,
  UiTable,
  UiTableProps,
  UiTooltip,
  UiTruncateMarkup,
  UiTypography,
  useFormBlocker,
  useModalBase,
  useSpace,
  useToggle,
} from '@vkph/ui';
import AddSvg from '@vkph/ui/svg/add.svg';
import ArrowBackSvg from '@vkph/ui/svg/arrow-back.svg';
import ArrowFrontSvg from '@vkph/ui/svg/arrow-front.svg';
import CopyFilledSvg from '@vkph/ui/svg/copy-filled.svg';
import DeleteSvg from '@vkph/ui/svg/delete.svg';
import DropDownSvg from '@vkph/ui/svg/drop-down.svg';
import DropUpSvg from '@vkph/ui/svg/drop-up.svg';
import FolderSvg from '@vkph/ui/svg/folder.svg';
import GearSvg from '@vkph/ui/svg/gear.svg';
import HideSvg from '@vkph/ui/svg/hide.svg';
import ViewSvg from '@vkph/ui/svg/view.svg';

import { ActionsDropdown, ActionsDropdownItem } from '../actions-dropdown';
import styles from './DynamicTable.scss';
import { DynamicTableCell, ListRowValuesWithId } from './cell';
import { getGroupNameByType } from './cell/utils';
import {
  DYNAMIC_TABLE_CELL_DATA_PREFIX,
  DYNAMIC_TABLE_CREATE_COLUMNS_ID,
  DYNAMIC_TABLE_MAX_COLUMNS_COUNT,
  DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX,
  DynamicTablePermission,
  listColumnTypeFields,
} from './constants';
import {
  BooleanFormFields,
  CreateUpdateColumn,
  DatetimeFormFields,
  DictionaryFormFields,
  EnumFormFields,
  FileFormFields,
  HyperlinkFormFields,
  NumericFormFields,
  TextFormFields,
  UserFormFields,
} from './create-update-column';
import { DynamicTableFilters } from './filters/DynamicTableFilters';
import { TableHeaderCell } from './header-cell/TableHeaderCell';
import { TableHeaderCellTitle } from './header-cell/title/TableHeaderCellTitle';
import { DynamicTableSettings, DynamicTableSettingsProps } from './list-settings/ListSettings';
import { normalizeRowValuesToForm, normalizeRowValuesToSave, normalizeValueCellToForm } from './normalize';
import { RowDetails } from './row-details/RowDetails';
import { DynamicTableSortButton } from './sort-button/DynamicTableSortButton';

export interface CreateColumnItem {
  id: string;
  name: string;
  fieldType: ListColumnFieldType;
  columnIndex?: number;
}

export type DynamicTableProps = {
  list: ListModel;
  rowsParams?: Partial<GetListRowsParams>;
  permissions: DynamicTablePermission[];
  // TODO:: Избавиться от этого в задаче https://jira.vk.team/browse/B2BCORE-11050
  // Пока не добавляем для пользовательского режима редактирования formBlocker
  // так как иначе у них постоянно будет выходить назойливый formBlocker на любой клик извне
  adminTable?: boolean;
  renderHeader?: (options: DynamicTableSettingsProps) => ReactNode;
};

type ColumnDropdownItemsType =
  | 'edit'
  | 'addColumn'
  | 'moveLeft'
  | 'moveRight'
  | 'hide'
  | 'duplicateColumn'
  | 'deleteColumn';

type RowDropdownItemsType = 'addRow' | 'duplicateRow' | 'deleteRow' | 'groupRowByCell';

export type ColumnDropdownItemsMap = Record<ColumnDropdownItemsType, UiMenuItemTypes>;
export type RowDropdownItemsMap = Record<RowDropdownItemsType, UiMenuItemTypes>;

export type GetColumnDropdownActionItems = (
  column: ListColumnModel,
  index: number,
) => Required<UiDropdownProps>['menu']['items'];

export type OnUpdateRowParams = {
  rowId: ListRowId;
  rowValues: ListRowValues;
};

export type OnGroupRowsParams = Pick<
  GlobalModalsStorePayloads[GlobalModalNames.GroupRowsEditor]['payload'],
  'rowIds' | 'groupByRowId' | 'groupByColumnId' | 'description'
>;

export type GetCreateColumnItems = (params?: {
  columnIndex?: number;
  showTooltip?: boolean;
}) => Required<UiDropdownProps>['menu']['items'];

export type GetColSpan = (params: {
  record: DynamicTableRow;
  columnCell: ListColumnModel;
  columnIndex: number;
  totalColSpan: number;
}) => number;

type DynamicTableComposition = {
  Settings: typeof DynamicTableSettings;
};

export type DynamicTableRow = ListRowValuesWithId & {
  children?: ListRowValuesWithId[];
  group?: ListGroup;
  isChildren?: boolean;
};

interface ListRowGroup
  extends Pick<ListRowModel, 'id'>,
    Pick<DynamicTableRow, 'children' | 'group' | 'isChildren'> {
  rowValues: ListRowValues;
}

const cancelModalProps = {
  title: 'Выйти из режима создания списка?',
  content: 'Вы уходите со страницы создания списка, все несохраненные данные могут быть утеряны.',
  okText: 'Выйти',
  cancelText: 'Отмена',
  centered: true,
};

const getDeleteModalProps = (isBatching: boolean) => ({
  title: `Вы уверены, что хотите удалить ${isBatching ? 'выбранные строки' : 'эту строку'}?`,
  content: `Все данные ${isBatching ? 'строк' : 'строки'} будут потеряны без возможности восстановления`,
  okText: `Удалить ${isBatching ? 'строки' : 'строку'}`,
  cancelText: 'Отменить удаление',
});

const getSuccessMessageDeleteRow = (isBatching: boolean) =>
  isBatching ? 'Строки успешно удалены' : 'Строка успешно удалена';

const getErrorMessageDeleteRow = (isBatching: boolean) =>
  isBatching ? 'Не удалось удалить строки' : 'Не удалось удалить строку';

const getReorderedIds = <T extends string | number>(ids: T[], id: T, index: number) => {
  const reorderedList = [...ids];
  const idIndex = reorderedList.indexOf(id);

  if (idIndex !== -1) {
    reorderedList.splice(idIndex, 1);
  }

  reorderedList.splice(index, 0, id);

  return reorderedList;
};

export const isColumnExist = (column: ListColumnModel | CreateColumnItem): column is ListColumnModel => {
  return column.id !== DYNAMIC_TABLE_CREATE_COLUMNS_ID;
};

const getSortedColumnsIds = (
  listColumns: ListColumnModel[],
  tableColumns: ColumnsType<ListRowValuesWithId>,
) => {
  const columnsMap = new Map<ListColumnId, ListColumnModel>(listColumns.map((column) => [column.id, column]));

  const sortedIds: ListColumnId[] = [];

  tableColumns.forEach(({ key }) => {
    if (key && typeof key === 'string' && columnsMap.has(key)) {
      sortedIds.push(key);
      columnsMap.delete(key);
    }
  });

  const restIds = Array.from(columnsMap, ([key]) => key);

  return sortedIds.concat(restIds);
};

const getTableRows = (rows: ListRowModel[], listColumnsData: ListColumnModel[]) => {
  const rowsDataMap = new Map<string, ListRowGroup>();

  rows.forEach((row) => {
    const { id, group, rowValues: rowModelValues } = row;
    const rowValues = normalizeRowValuesToForm(rowModelValues, listColumnsData);

    if (!group) {
      rowsDataMap.set(id, { id, rowValues });
    }

    if (group) {
      const groupId = group.id;
      const rowChildren = {
        id,
        isChildren: true,
        ...rowValues,
      };

      if (rowsDataMap.has(groupId)) {
        rowsDataMap.get(groupId)?.children?.push(rowChildren);
      } else {
        rowsDataMap.set(groupId, {
          rowValues,
          group,
          id: groupId,
          children: [rowChildren],
        });
      }
    }
  });

  return Array.from(rowsDataMap, ([_, { id, rowValues, children, group }]) => {
    return merge({ id, children, group }, rowValues);
  });
};

export const DynamicTable: FC<DynamicTableProps> & DynamicTableComposition = (props) => {
  const { list, rowsParams, permissions = [], adminTable = false, renderHeader } = props;
  const { id: listId, siteId } = list;
  const [form] = UiForm.useForm();

  const permissionMap = useMemo(() => {
    return new Map(permissions.map((permission) => [permission, permission]));
  }, [permissions]);

  const readOnly = permissionMap.size === 0;

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
  const [isBlocked, toggleIsBlocked] = useToggle([true, false]);
  const { confirm, warning } = useModalBase();
  const { spaceL, spaceXS, space3XL, space2XS } = useSpace();
  const [currentIdRowDetails, setCurrentIdRowDetails] = useState('');

  const listVirtualRowStorage = useMemo(getListVirtualRowStorage, []);
  const { store, createVirtualRowEvent, updateVirtualRowEvent, deleteVirtualRowsEvent } =
    listVirtualRowStorage;
  const virtualRows = useStore(store);

  const listColumnsStorage = useMemo(getListColumnsStorage, []);

  const {
    copyListColumnEffect,
    updateListColumnsOrderEffect,
    deleteListColumnEffect,
    toggleVisibleListColumnEffect,
  } = listColumnsStorage;

  const listRowsStorage = useMemo(getListRowsStorage, []);

  const { createRowEffect, deleteListRowsBatchEffect, updateListRowEffect, paramsStore } = listRowsStorage;

  const isFormBlocked = adminTable && isBlocked && !currentIdRowDetails;
  const formBlockerRef = useFormBlocker<HTMLDivElement>(isFormBlocked, 'confirm', cancelModalProps);
  // TODO: Убрать передачу рефа во вложенные компоненты после https://jira.vk.team/browse/B2BCORE-11050
  const ref = adminTable ? formBlockerRef : undefined;

  const [createColumnState, setCreateColumnState] = useState<CreateColumnItem | null>(null);
  const [editColumnId, setEditColumnId] = useState<ListColumnId | null>(null);
  const [columnsOptimisticOrder, setColumnsOptimisticOrder] = useState<string[] | null>(null);

  const rowsParamsData = useStore(paramsStore.store);

  const onCancelColumnCreate = () => {
    setCreateColumnState(null);
  };

  const onCancelColumnEdit = () => {
    setEditColumnId(null);
  };

  const onCreateCell = (options: Pick<CreateColumnItem, 'fieldType' | 'columnIndex'>) => {
    const { fieldType, columnIndex } = options;

    setCreateColumnState({
      id: DYNAMIC_TABLE_CREATE_COLUMNS_ID,
      name: listColumnTypeFields[fieldType].label,
      fieldType,
      columnIndex,
    });
  };

  const optionsListColumnStorage = {
    autoFetchAndRefetch: true,
    autoFetchParams: { id: listId },
    resetStoreOnChangeParams: { listId },
  };

  const optionsListRowsStorage = {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      id: listId,
      ...rowsParams,
      ...rowsParamsData,
    },
    resetStoreOnChangeParams: { listId },
  };

  const { data: listColumnsData, loading: isColumnsLoading } = useAbstractStorage(
    listColumnsStorage.storage,
    optionsListColumnStorage,
  );

  const { data: listRowsData, loading: isRowsLoading } = useAbstractStorage(
    listRowsStorage.storage,
    optionsListRowsStorage,
  );

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: UiTableProps<DynamicTableRow>['rowSelection'] = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
    getCheckboxProps: (record) => {
      return {
        disabled: Boolean(record.group || record.id.includes(DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX)),
      };
    },
  };

  const dataSource = useMemo(() => {
    const tableRows = getTableRows(listRowsData, listColumnsData);
    const virtualRowsMapped = virtualRows.map(({ id, rowValues }) => {
      return merge({ id, children: undefined }, rowValues);
    });

    return [...tableRows, ...virtualRowsMapped];
  }, [listRowsData, listColumnsData, virtualRows]);

  const isCreateColumnDisabled = listColumnsData.length === DYNAMIC_TABLE_MAX_COLUMNS_COUNT;

  const getCreateColumnItems: GetCreateColumnItems = (params) => {
    const { columnIndex, showTooltip = true } = params || {};

    return Object.values(ListColumnFieldType).map((fieldType) => {
      const { label, icon, description } = listColumnTypeFields[fieldType];

      return {
        key: fieldType,
        icon: <UiIcon width={20} height={20} component={icon} />,
        onClick: () => onCreateCell({ fieldType, columnIndex }),
        label: (
          <UiTooltip
            open={showTooltip ? undefined : false}
            placement="left"
            title={description}
            shift={-25}
            getPopupContainer={(triggerNode) => triggerNode}
          >
            {label}
          </UiTooltip>
        ),
      };
    });
  };

  const getColumnIndex = (column: ListColumnModel) => {
    return listColumnsData.findIndex(({ id }) => id === column.id);
  };

  const onReorderColumn = (columnId: ListColumnId, toIndex: number) => {
    const columnsIds = listColumnsData.map(({ id }) => id);
    const reorderedColumnsIds = getReorderedIds(columnsIds, columnId, toIndex);

    return updateListColumnsOrderEffect({ id: listId, order: reorderedColumnsIds });
  };

  const onColumnDragEnd = (reorderedColumns: ColumnsType<ListRowValuesWithId>) => {
    const reorderedColumnsIds = getSortedColumnsIds(listColumnsData, reorderedColumns);

    setColumnsOptimisticOrder(reorderedColumnsIds);

    return updateListColumnsOrderEffect({ id: listId, order: reorderedColumnsIds })
      .catch((e) =>
        notification.error({ message: getErrorResponseMessage(e, 'Не удалось изменить порядок столбцов') }),
      )
      .finally(() => setColumnsOptimisticOrder(null));
  };

  const onHideColumn = (columnId: ListColumnId) => {
    return toggleVisibleListColumnEffect({ id: listId, columnId }).then(() => {
      notification.success({
        message: 'Столбец успешно скрыт',
        description: 'Столбец скрыт в списке и значения в нём стали необязательными',
      });
    });
  };

  const onColumnDelete = (columnId: ListColumnId) => {
    toggleIsBlocked(false);
    warning({
      title: 'Вы уверены, что хотите удалить этот столбец?',
      content: 'Все данные столбца будут потеряны без возможности восстановления',
      okText: 'Удалить',
      cancelText: 'Отмена',
      centered: true,
      onCancel: toggleIsBlocked,
      onOk: () => {
        deleteListColumnEffect({ id: listId, columnId }).then(() => toggleIsBlocked());
      },
    });
  };

  const onDuplicateColumn = (columnId: ListColumnId, columnIndex: number) => {
    copyListColumnEffect({ id: listId, columnId }).then(({ id }) => {
      if (columnIndex !== listColumnsData.length - 1) {
        onReorderColumn(id, columnIndex + 1);
      }
    });
  };

  const validateRow = async (params: OnUpdateRowParams, isVirtualRow: boolean) => {
    const { rowId, rowValues } = params;

    try {
      await form.validateFields(listColumnsData.map(({ id }) => [DYNAMIC_TABLE_CELL_DATA_PREFIX, rowId, id]));

      return true;
    } catch {
      if (isVirtualRow) {
        updateVirtualRowEvent({ id: rowId, rowValues });
      }

      return false;
    }
  };

  const onUpdateRow = async (params: OnUpdateRowParams) => {
    const { rowId, rowValues } = params;
    const isVirtualRow = rowId.includes(DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX);
    const isValidated = await validateRow(params, isVirtualRow);

    if (!isValidated) return;

    try {
      if (isVirtualRow) {
        await createRowEffect({
          id: listId,
          rowValues: normalizeRowValuesToSave(rowValues, listColumnsData),
        });

        deleteVirtualRowsEvent([rowId]);
      } else {
        await updateListRowEffect({
          id: listId,
          rowId,
          rowValues: normalizeRowValuesToSave(rowValues, listColumnsData),
        });

        notification.success({
          message: 'Ячейка успешно изменена',
        });

        if (listColumnsData.some(({ fieldOptions }) => fieldOptions.groupValues)) {
          listRowsStorage.storage.refetchWithLastParams();
        }
      }
    } catch (e) {
      notification.error({
        message: getErrorResponseMessage(e, `Не удалось ${isVirtualRow ? 'добавить' : 'обновить'} строку`),
      });
    }
  };

  const addRow = (values?: ListRowValues, insertAfterId?: ListRowId) => {
    const isRequiredColumns = listColumnsData.some((data) => data.isRequired);
    const rowValues =
      values ||
      Object.fromEntries(
        listColumnsData.map((column) => [
          column.id,
          column.fieldOptions.default
            ? normalizeValueCellToForm(column.fieldOptions.default, column.fieldType)
            : defaultValueCell[column.fieldType],
        ]),
      );

    if (isRequiredColumns && !insertAfterId) {
      createVirtualRowEvent({
        id: `${DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX}-${generateRandomString()}`,
        listId,
        rowValues,
      });
    } else {
      createRowEffect({
        id: listId,
        rowValues,
        insertAfterId,
      }).catch((e) =>
        notification.error({ message: getErrorResponseMessage(e, 'Не удалось добавить строку') }),
      );
    }
  };

  const onDuplicateRow = useCallback(
    (row: ListRowValuesWithId) => {
      const { id, ...data } = row;
      const isVirtual = id.includes(DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX);

      addRow(normalizeRowValuesToSave(data, listColumnsData), isVirtual ? undefined : id);
    },
    [listColumnsData],
  );

  const onDeleteRow = (rowIds: ListRowId | ListRowId[]) => {
    const isBatching = Array.isArray(rowIds);

    toggleIsBlocked(false);

    confirm({
      ...getDeleteModalProps(isBatching),
      onCancel: () => toggleIsBlocked(true),
      onOk: async () => {
        try {
          setSelectedRowKeys((prev) => prev.filter((key) => !rowIds.includes(String(key))));

          const [virtualRowIds, basicRowIds] = isBatching
            ? partition(rowIds, (id) => id.includes(DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX))
            : [...(rowIds.includes(DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX) ? [[rowIds], []] : [[], [rowIds]])];

          if (virtualRowIds.length > 0) {
            deleteVirtualRowsEvent(virtualRowIds);
          }

          if (basicRowIds.length > 0) {
            await deleteListRowsBatchEffect({ listId, rowIds: basicRowIds });
          }

          notification.success({
            message: getSuccessMessageDeleteRow(isBatching),
          });
        } catch (e) {
          notification.error({
            message: getErrorResponseMessage(e, getErrorMessageDeleteRow(isBatching)),
          });
        } finally {
          toggleIsBlocked(true);
        }
      },
    });
  };

  const cleanUpSelectionRow = () => {
    setSelectedRowKeys([]);
  };

  const onGroupRows = (params: OnGroupRowsParams) => {
    const { groupByRowId, groupByColumnId, rowIds, description } = params;

    toggleIsBlocked(false);

    openGlobalModal(GlobalModalNames.GroupRowsEditor, {
      listId,
      rowIds,
      groupByRowId,
      groupByColumnId,
      description,
      onSuccess: () => {
        toggleIsBlocked(true);
        cleanUpSelectionRow();
        listRowsStorage.storage.refetchWithLastParams();
      },
      onClose: () => {
        toggleIsBlocked(true);
        closeGlobalModal(GlobalModalNames.GroupRowsEditor);
      },
    });
  };

  const onGroupRowsByValue = (row: DynamicTableRow, column: ListColumnModel) => {
    const { id: dataIndex, fieldType, fieldOptions } = column;
    const { id: rowId } = row;

    if (rowId.includes(DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX)) {
      return null;
    }

    const value = row[dataIndex];
    const groupName = getGroupNameByType({
      value,
      fieldType,
      fieldOptions,
      defaultName: value ? value.toString() : '',
    });

    return onGroupRows({
      groupByColumnId: column.id,
      groupByRowId: row.id,
      description: (
        <UiFlex gap={space2XS} wrap>
          <UiTypography.Text>Строки будут сгруппированы по значению ячейки</UiTypography.Text>
          <UiTypography.Text strong>
            <UiTruncateMarkup
              lines={1}
              truncate
              tooltipProps={{ title: groupName }}
            >{`"${groupName}"`}</UiTruncateMarkup>
          </UiTypography.Text>
        </UiFlex>
      ),
    });
  };

  const getRowDropdownItemsMap = (row: DynamicTableRow, column: ListColumnModel): RowDropdownItemsMap => {
    return {
      addRow: {
        key: 'addRow',
        label: 'Добавить строку вниз',
        icon: <UiIcon width={20} height={20} component={AddSvg} />,
        onClick: () => addRow(),
        disabled: !permissionMap.has(DynamicTablePermission.EditRows),
      },
      duplicateRow: {
        key: 'duplicateRow',
        label: 'Дублировать строку',
        icon: <UiIcon width={20} height={20} component={CopyFilledSvg} />,
        onClick: () => onDuplicateRow(row),
        disabled: !permissionMap.has(DynamicTablePermission.EditRows),
      },
      deleteRow: {
        key: 'deleteRow',
        label: 'Удалить строку',
        icon: <UiIcon width={20} height={20} component={DeleteSvg} />,
        onClick: () => onDeleteRow(row.id),
        disabled: !permissionMap.has(DynamicTablePermission.DeleteRows),
      },
      groupRowByCell: {
        key: 'groupRowByCell',
        label: (
          <>
            Сгруппировать строки
            <br />
            по значению ячейки
          </>
        ),
        icon: <UiIcon width={20} height={20} component={FolderSvg} />,
        onClick: () => onGroupRowsByValue(row, column),
        disabled: !permissionMap.has(DynamicTablePermission.GroupRows),
      },
    };
  };

  const getColumnDropdownItemsMap = (
    column: ListColumnModel,
    columnIndex: number,
  ): ColumnDropdownItemsMap => {
    const { id } = column;
    const visibleColumnsData = listColumnsData.filter(({ isVisible }) => isVisible);
    const isFirstColumn = columnIndex === 0;
    const isLastColumn = columnIndex === visibleColumnsData.length - 1;

    return {
      edit: {
        key: 'edit',
        label: 'Настройка столбца',
        icon: <UiIcon width={20} height={20} component={GearSvg} />,
        onClick: () => setEditColumnId(id),
        disabled: !permissionMap.has(DynamicTablePermission.EditColumns),
      },
      addColumn: {
        key: 'addColumn',
        label: 'Добавить столбец справа',
        icon: <UiIcon width={20} height={20} component={AddSvg} />,
        children: getCreateColumnItems({ columnIndex, showTooltip: false }),
        disabled: isCreateColumnDisabled || !permissionMap.has(DynamicTablePermission.EditColumns),
      },
      moveLeft: {
        key: 'moveLeft',
        label: 'Переместить влево',
        icon: <UiIcon width={20} height={20} component={ArrowBackSvg} />,
        onClick: () => onReorderColumn(id, getColumnIndex(visibleColumnsData[columnIndex - 1])),
        disabled: isFirstColumn || !permissionMap.has(DynamicTablePermission.EditColumns),
      },
      moveRight: {
        key: 'moveRight',
        label: 'Переместить вправо',
        icon: <UiIcon width={20} height={20} component={ArrowFrontSvg} />,
        onClick: () => onReorderColumn(id, getColumnIndex(visibleColumnsData[columnIndex + 1])),
        disabled: isLastColumn || !permissionMap.has(DynamicTablePermission.EditColumns),
      },
      hide: {
        key: 'hide',
        label: 'Скрыть столбец',
        icon: <UiIcon width={20} height={20} component={HideSvg} />,
        onClick: () => onHideColumn(id),
        disabled: !permissionMap.has(DynamicTablePermission.EditColumns),
      },
      duplicateColumn: {
        key: 'duplicateColumn',
        label: 'Дублировать столбец',
        icon: <UiIcon width={20} height={20} component={CopyFilledSvg} />,
        onClick: () => onDuplicateColumn(column.id, columnIndex),
        disabled: !permissionMap.has(DynamicTablePermission.EditColumns),
      },
      deleteColumn: {
        key: 'deleteColumn',
        label: 'Удалить столбец',
        icon: <UiIcon width={20} height={20} component={DeleteSvg} />,
        onClick: () => onColumnDelete(id),
        disabled: !permissionMap.has(DynamicTablePermission.DeleteColumns),
      },
    };
  };

  const getColumnDropdownActionItems = (column: ListColumnModel, index: number) => {
    const { edit, duplicateColumn, deleteColumn } = getColumnDropdownItemsMap(column, index);

    return [edit, duplicateColumn, deleteColumn];
  };

  const columns = useMemo<ColumnsType<DynamicTableRow>>(() => {
    const columnsData: (ListColumnModel | CreateColumnItem)[] = listColumnsData.filter(
      ({ isVisible }) => isVisible,
    );

    if (columnsOptimisticOrder) {
      columnsData.sort((a, b) => columnsOptimisticOrder.indexOf(a.id) - columnsOptimisticOrder.indexOf(b.id));
    }

    if (createColumnState) {
      const { columnIndex } = createColumnState;

      if (columnIndex === undefined) {
        columnsData.push(createColumnState);
      } else {
        columnsData.splice(columnIndex + 1, 0, createColumnState);
      }
    }

    const formFieldsMap = {
      [ListColumnFieldType.Text]: <TextFormFields />,
      [ListColumnFieldType.Numeric]: <NumericFormFields />,
      [ListColumnFieldType.Boolean]: <BooleanFormFields />,
      [ListColumnFieldType.Datetime]: <DatetimeFormFields />,
      [ListColumnFieldType.Dictionary]: <DictionaryFormFields />,
      [ListColumnFieldType.Enum]: <EnumFormFields />,
      [ListColumnFieldType.Hyperlink]: <HyperlinkFormFields />,
      [ListColumnFieldType.User]: <UserFormFields />,
      [ListColumnFieldType.File]: <FileFormFields id={siteId} />,
    };

    return columnsData.map((column, index) => {
      const { id, fieldType } = column;
      const isEdit = editColumnId === id;
      const isCreate = id === DYNAMIC_TABLE_CREATE_COLUMNS_ID;
      const isLastColumn = index === listColumnsData.length - 1;

      const onCreateSuccess = (columnId: ListColumnId) => {
        if (!isLastColumn) {
          onReorderColumn(columnId, index);
        }
      };

      const getColSpan: GetColSpan = ({ record, columnCell, columnIndex, totalColSpan }) => {
        const isGroupRow = record.children;
        const isGroupType = (type: ListRowGroupType) => type === record.group?.groupType;
        const isFillSpanColumn = isGroupType(ListRowGroupType.AutoColumn)
          ? columnCell.fieldOptions.groupValues
          : columnIndex === 0;

        if (isGroupRow) {
          return isFillSpanColumn ? totalColSpan : 0;
        }

        return 1;
      };

      return {
        title: (
          <TableHeaderCell
            column={column}
            open={isEdit || isCreate}
            containerRef={ref}
            extra={
              isColumnExist(column) && (
                <UiFlex gap={spaceXS}>
                  <DynamicTableSortButton listRowsStorage={listRowsStorage} column={column} />
                  <DynamicTableFilters listRowsStorage={listRowsStorage} containerRef={ref} column={column} />
                </UiFlex>
              )
            }
            popoverContent={
              <CreateUpdateColumn
                listId={listId}
                storage={listColumnsStorage}
                rowStorage={listRowsStorage.storage}
                fieldType={fieldType}
                onClose={isCreate ? onCancelColumnCreate : onCancelColumnEdit}
                columnId={id !== DYNAMIC_TABLE_CREATE_COLUMNS_ID ? id : undefined}
                onCreateSuccess={onCreateSuccess}
                formFieldsMap={formFieldsMap}
              />
            }
            dropdownActions={
              isColumnExist(column) ? Object.values(getColumnDropdownItemsMap(column, index)) : undefined
            }
          />
        ),
        dataIndex: id,
        key: id,
        width: 250,
        onCell: (record) => {
          return {
            style: { padding: 1, verticalAlign: 'top' },
            onClick: () => toggleIsBlocked(false),
            ...(isColumnExist(column) && {
              colSpan: getColSpan({
                record,
                columnCell: column,
                columnIndex: index,
                totalColSpan: columnsData.length,
              }),
              className: classNames({
                [styles.dynamicTable__table__childrenCell]: record?.isChildren,
                [styles.dynamicTable__table__groupCell]: record?.group,
              }),
            }),
          };
        },
        render: (_, record) => {
          return isColumnExist(column) ? (
            <DynamicTableCell
              toggleIsBlockedTable={toggleIsBlocked}
              readOnly={!permissionMap.has(DynamicTablePermission.EditCells)}
              columnDropdownItemsMap={getColumnDropdownItemsMap(column, index)}
              rowDropdownItemsMap={getRowDropdownItemsMap(record, column)}
              onUpdate={onUpdateRow}
              column={column}
              containerRef={ref}
              record={record}
              rowStorage={listRowsStorage.storage}
            />
          ) : null;
        },
      };
    });
  }, [listColumnsData, createColumnState, editColumnId, permissionMap, columnsOptimisticOrder]);

  const dropdownActions = useCallback(
    (row: ListRowValuesWithId): ActionsDropdownItem[] => {
      const { id } = row;
      const result = [];

      if (!id.includes(DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX)) {
        result.push({
          label: 'Информация о строке',
          icon: ViewSvg,
          onClick: () => setCurrentIdRowDetails(id),
        });
      }

      if (
        permissionMap.has(DynamicTablePermission.EditRows) &&
        !id.includes(DYNAMIC_TABLE_VIRTUAL_ROW_PREFIX)
      ) {
        result.push({
          label: 'Дублировать строку вниз',
          icon: CopyFilledSvg,
          onClick: () => onDuplicateRow(row),
        });
      }

      if (permissionMap.has(DynamicTablePermission.DeleteRows)) {
        result.push({ label: 'Удалить строку', icon: DeleteSvg, onClick: () => onDeleteRow(row.id) });
      }

      return result;
    },
    [permissionMap, onDuplicateRow],
  );

  const renderActions = useCallback(
    (_: unknown, record: DynamicTableRow) => {
      const { id, children } = record;
      const items = dropdownActions(record);
      const isExpand = expandedRowKeys.includes(id);

      const onExpand = () => {
        setExpandedRowKeys((keys) => (isExpand ? keys.filter((key) => key !== id) : [...keys, id]));
      };

      return (
        <UiFlex justify="end" gap={space3XL}>
          {children && (
            <UiButton type="link" onClick={onExpand}>
              {`${isExpand ? 'Скрыть' : 'Показать'} строки`}
              <UiIcon component={isExpand ? DropUpSvg : DropDownSvg} width={20} height={20} />
            </UiButton>
          )}
          {!children && (
            <ActionsDropdown
              className={styles.dynamicTable__dropdownActionButton}
              items={items}
              recordId={id}
              getPopupContainer={() => ref?.current || document.body}
            />
          )}
        </UiFlex>
      );
    },
    [dropdownActions, expandedRowKeys],
  );

  const selectAllRows = () => {
    setSelectedRowKeys(dataSource.map((item) => item.id));
  };

  const isLoading = isColumnsLoading || isRowsLoading;
  const rowData = listRowsData.find((row) => row.id === currentIdRowDetails);

  const selectionHeaderExtra = (
    <UiSpace>
      {permissionMap.has(DynamicTablePermission.GroupRows) && (
        <UiButton
          icon={<UiIcon component={FolderSvg} width={20} height={20} />}
          onClick={() => onGroupRows({ rowIds: selectedRowKeys as ListRowId[] })}
          type="link-secondary"
          disabled={!selectedRowKeys.length}
          label="Сгруппировать"
        />
      )}
      {permissionMap.has(DynamicTablePermission.DeleteRows) && (
        <UiButton
          icon={<UiIcon component={DeleteSvg} width={20} height={20} />}
          onClick={() => onDeleteRow(selectedRowKeys as ListRowId[])}
          type="link-secondary"
          disabled={!selectedRowKeys.length}
          label="Удалить"
        />
      )}
    </UiSpace>
  );

  const addColumnDropdown = useMemo(() => {
    return (
      <UiFlex justify="end">
        <UiDropdown
          getPopupContainer={() => ref?.current || document.body}
          trigger={['click']}
          disabled={isCreateColumnDisabled}
          menu={{
            items: [
              {
                key: '1',
                label: 'Тип столбца',
                type: 'group',
                children: getCreateColumnItems(),
              },
            ],
          }}
        >
          <UiButton type="link-secondary" icon={<UiIcon width={20} height={20} component={AddSvg} />} />
        </UiDropdown>
      </UiFlex>
    );
  }, [ref, isCreateColumnDisabled, getCreateColumnItems]);

  const dragDropTableProps: UiTableProps<DynamicTableRow>['draggableProps'] = useMemo(() => {
    const activatorColumnProps = {
      render: renderActions,
    };

    if (permissionMap.has(DynamicTablePermission.EditColumns)) {
      return {
        onColumnDragEnd,
        activatorColumnProps: {
          ...activatorColumnProps,
          title: addColumnDropdown,
          width: undefined,
        },
        renderCellOverlay: ({ id }) => {
          const activeColumn = listColumnsData.find((item) => item.id === id);

          return (
            activeColumn && (
              <TableHeaderCellTitle label={activeColumn.name} fieldType={activeColumn.fieldType} />
            )
          );
        },
      };
    }

    return {
      activatorColumnProps,
    };
  }, [onColumnDragEnd, addColumnDropdown, renderActions, listColumnsData, permissionMap]);

  return (
    <UiFlex
      ref={ref}
      vertical
      gap={spaceL}
      className={classNames(styles.dynamicTable, {
        [styles.dynamicTable_fixHeight]: adminTable,
      })}
    >
      {renderHeader?.({
        listId,
        getColumnDropdownActionItems,
        getCreateColumnItems,
        listColumnsStorage,
        containerRef: ref,
      })}
      <UiForm form={form}>
        <UiTable<DynamicTableRow>
          className={styles.dynamicTable__table}
          dataSource={dataSource}
          pagination={false}
          loading={isLoading}
          isTitleEmptyPadding={!isLoading}
          rowSelection={isLoading || readOnly ? undefined : rowSelection}
          columns={columns}
          scroll={{ x: 'max-content' }}
          rowKey="id"
          expandable={{
            expandedRowKeys,
            expandIcon: () => undefined,
            indentSize: 0,
          }}
          title={
            readOnly
              ? undefined
              : () => (
                  <UiRender type={UiRenderType.DisplayNone} visible={Boolean(selectedRowKeys.length)}>
                    <UiTable.SelectionHeader
                      extra={selectionHeaderExtra}
                      totalCount={dataSource?.length}
                      selectedCount={selectedRowKeys.length}
                    >
                      <UiButton type="link" onClick={selectAllRows}>
                        Выбрать все
                      </UiButton>
                      <UiButton type="link-secondary" onClick={cleanUpSelectionRow}>
                        Отмена
                      </UiButton>
                    </UiTable.SelectionHeader>
                  </UiRender>
                )
          }
          footer={
            permissionMap.has(DynamicTablePermission.EditRows)
              ? () => (
                  <UiButton
                    icon={<UiIcon width={20} height={20} component={AddSvg} />}
                    label="Добавить строку"
                    onClick={() => addRow()}
                    type="link"
                  />
                )
              : undefined
          }
          draggableProps={dragDropTableProps}
        />
      </UiForm>
      {rowData && (
        <RowDetails
          rowData={rowData}
          columnsData={listColumnsData}
          open={Boolean(currentIdRowDetails)}
          onClose={() => setCurrentIdRowDetails('')}
        />
      )}
    </UiFlex>
  );
};

DynamicTable.Settings = DynamicTableSettings;
