import { UiButton, UiDropdown, UiDropdownProps, UiIcon, UiPopover, UiPopoverProps, useSpace } from '@vkph/ui';
import React, { FC, ReactNode, RefObject } from 'react';

import { ListColumnModel } from '@vkph/common/types/models';
import DropRightSvg from '@vkph/ui/svg/drop-right.svg';
import MeatballSvg from '@vkph/ui/svg/meatball.svg';

import { CreateColumnItem } from '../DynamicTable';
import { DYNAMIC_TABLE_CREATE_COLUMNS_ID } from '../constants';
import { TableHeaderCellTitle } from './title/TableHeaderCellTitle';

interface Props {
  column: ListColumnModel | CreateColumnItem;
  open: boolean;
  popoverContent: UiPopoverProps['content'];
  dropdownActions?: Required<UiDropdownProps>['menu']['items'];
  containerRef?: RefObject<HTMLDivElement>;
  extra?: ReactNode;
}

export const TableHeaderCell: FC<Props> = (props) => {
  const { column, open, containerRef, popoverContent, dropdownActions, extra } = props;
  const { id, name, fieldType } = column;
  const { spaceXL } = useSpace();
  const isCreating = id === DYNAMIC_TABLE_CREATE_COLUMNS_ID;

  return (
    <UiPopover
      align={{ offset: [-spaceXL, spaceXL] }}
      placement="bottomLeft"
      getPopupContainer={() => containerRef?.current || document.body}
      arrow={false}
      overlayInnerStyle={{ padding: spaceXL }}
      content={popoverContent}
      trigger="click"
      open={open}
    >
      <TableHeaderCellTitle label={name} fieldType={fieldType} isCreating={isCreating}>
        {extra}
        {dropdownActions && (
          <UiDropdown
            arrow
            disabled={isCreating}
            getPopupContainer={() => containerRef?.current || document.body}
            trigger={['click']}
            placement="bottom"
            menu={{
              items: dropdownActions,
              expandIcon: <UiIcon width={20} height={20} component={DropRightSvg} />,
              getPopupContainer: () => containerRef?.current || document.body,
            }}
          >
            <UiButton
              icon={<UiIcon width={20} height={20} component={MeatballSvg} />}
              type="link-secondary"
            />
          </UiDropdown>
        )}
      </TableHeaderCellTitle>
    </UiPopover>
  );
};
