import { UiAvatar, UiTooltip, UiTypography } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserModel } from '@vkph/common/types/models';
import { getRoutePath, RouteNames } from '@vkph/common/utils';

import styles from './AvatarsTooltip.scss';

type AvatarsTooltipProps = {
  children: ReactNode;
  users: UserModel[];
  title: string | ReactNode;
  maxCount?: number;
  onClickSuffix?: () => void;
  isActivate?: boolean;
  visible?: boolean;
};

export const AvatarsTooltip: FC<AvatarsTooltipProps> = (avatarsTooltipProps) => {
  const {
    children,
    users,
    title,
    maxCount = 5,
    onClickSuffix,
    isActivate = true,
    ...props
  } = avatarsTooltipProps;
  const navigate = useNavigate();

  if (!users || users.length === 0 || !isActivate) {
    return <>{children}</>;
  }

  const onOpenUserProfile = (user: UserModel) => {
    const id = user.keycloakUser?.keycloakId || user.id;

    navigate(getRoutePath(RouteNames.Profile, { id }));
  };

  const tooltipContent = (
    <>
      {typeof title === 'string' ? (
        <UiTypography.Text className={classNames(styles.avatarsTooltip__title, styles.avatarsTooltip_center)}>
          {title}
        </UiTypography.Text>
      ) : (
        <>{title}</>
      )}
      <UiAvatar.Group
        max={{ count: maxCount }}
        onClickSuffix={onClickSuffix}
        size="small"
        className={classNames(styles.avatarsTooltip__group, styles.avatarsTooltip_center)}
      >
        {users.map((user) => (
          <UiTypography.Link key={user.id} onClick={() => onOpenUserProfile(user)}>
            <UiAvatar key={user.id} src={user.avatar} size="small" />
          </UiTypography.Link>
        ))}
      </UiAvatar.Group>
    </>
  );

  return (
    <UiTooltip title={tooltipContent} {...props}>
      {children}
    </UiTooltip>
  );
};
