import { UiImage, UiImageProps } from '@vkph/ui';
import React, { CSSProperties, FC } from 'react';

export const PostCover: FC<UiImageProps> = (props) => {
  const { wrapperStyle, ...rest } = props;

  const mergedWrapperStyle: CSSProperties = {
    aspectRatio: '16 / 9',
    ...wrapperStyle,
  };

  return <UiImage wrapperStyle={mergedWrapperStyle} {...rest} />;
};
