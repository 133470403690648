import { UiCheckbox, UiList, UiFileDirection } from '@vkph/ui';
import React, { FC, useCallback } from 'react';

import { FileStorageListEntryModel } from '@vkph/common/types/models';
import { AbsolutePlacement, usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import { ActionsDropdownItem } from '../../../actions-dropdown';
import { FileProps } from '../../../file';
import { FileStorageListItemSelect } from '../../FileStorageList';
import { FileStorageListItemFile } from '../file';

type FileStorageItemFunction = (item: FileStorageListEntryModel) => void;

export interface FileStorageListItemInnerProps extends Pick<FileProps, 'direction'> {
  fileSelect?: FileStorageListItemSelect;
  onClick: FileStorageItemFunction;
  file: FileStorageListEntryModel;
  onFileSelect?: FileStorageItemFunction;
  getFileActions: (file: FileStorageListEntryModel) => ActionsDropdownItem[];
}

export const FileStorageListItemInner: FC<FileStorageListItemInnerProps> = (props) => {
  const { onClick, onFileSelect, fileSelect, file, direction, getFileActions, ...restProps } = props;

  const { spaceXS, spaceXL } = useSpace();
  const { padding } = usePaddingStyle([spaceXS, spaceXL]);

  const onItemClick = useCallback(() => {
    if (fileSelect) {
      onFileSelect?.(file);
    } else {
      onClick(file);
    }
  }, [file, fileSelect, onClick, onFileSelect]);

  return (
    <UiList.Item
      style={{
        padding: direction === UiFileDirection.Horizontal ? padding : 0,
        borderRadius: direction === UiFileDirection.Vertical ? 4 : 0,
      }}
      hoverable
      onClick={onItemClick}
    >
      {fileSelect && (
        <UiCheckbox
          style={{ marginRight: direction === UiFileDirection.Horizontal ? spaceXL : 0 }}
          placement={direction === UiFileDirection.Vertical ? AbsolutePlacement.TopRight : undefined}
          shift={10}
          value={file.id}
        />
      )}
      <FileStorageListItemFile
        {...restProps}
        getFileActions={getFileActions}
        file={file}
        direction={direction}
      />
    </UiList.Item>
  );
};
