import { UiTypography } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { useSpace } from '@vkph/ui/providers/space';

import { SurveyQuestionSection, SurveyQuestionSectionProps } from './section/SurveyQuestionSection';

export interface SurveyQuestionProps extends SurveyQuestionSectionProps {
  title: string;
}

export const SurveyQuestion: FC<PropsWithChildren<SurveyQuestionProps>> = (componentProps) => {
  const { title, children, ...props } = componentProps;
  const { spaceM: spaceMpx } = useSpace({ unit: 'px' });

  return (
    <SurveyQuestionSection {...props}>
      <UiTypography.Title level={3} style={{ margin: `0 0 ${spaceMpx}` }}>
        {title}
      </UiTypography.Title>
      {children}
    </SurveyQuestionSection>
  );
};
