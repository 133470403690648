import {
  UiIcon,
  UiButton,
  UiOverlay,
  AbsolutePlacement,
  useAbsolutePlacementShift,
  UiButtonProps,
  UiOverlayButtonProps,
} from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import UploadSvg from '@vkph/ui/svg/upload.svg';

export interface OverlayButtonBottomProps
  extends UiOverlayButtonProps,
    Pick<UiButtonProps, 'label' | 'icon'> {}

export const OverlayButtonBottom: FC<PropsWithChildren<OverlayButtonBottomProps>> = (props) => {
  const { label, icon: iconBtn, children, ...overlayProps } = props;
  const { placementStyles, placementCls } = useAbsolutePlacementShift({
    placement: AbsolutePlacement.BottomCenter,
  });

  const icon = <UiIcon component={UploadSvg} width={20} height={20} />;
  const button = (
    <UiButton
      block
      size="large"
      type="action"
      icon={iconBtn || icon}
      label={label}
      style={placementStyles}
      className={placementCls}
    />
  );

  return (
    <UiOverlay.Button elements={button} {...overlayProps}>
      {children}
    </UiOverlay.Button>
  );
};
