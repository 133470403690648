import { ImageConfig } from '@ckeditor/ckeditor5-image/src/imageconfig';
import { MentionFeedObjectItem } from '@ckeditor/ckeditor5-mention/src/mentionconfig';

import { CropImageAreaFigure } from '@vkph/common/types';
import { UserAvatarModel, UserIdModel } from '@vkph/common/types/models';

export enum EditorMentionType {
  User = 'user',
}

export type EditorSearchMentionsHandler<T> = (query: string) => Promise<T[]>;
export type GetEditorMentionFeedParams<T extends MentionFeedObjectItem = MentionFeedObjectItem> = {
  type: EditorMentionType;
  onSearchMentions: EditorSearchMentionsHandler<T>;
};

export interface UserMentionFeedItem extends MentionFeedObjectItem, Pick<UserAvatarModel, 'avatar'> {
  name: string;
  userId: UserIdModel;
  link: string;
}

export type EditorExtraButtonId = string;

export interface EditorExtraButton {
  id: EditorExtraButtonId;
  label: string;
  onClick: (params?: unknown) => void;
  icon?: SvgrComponent;
}

export type ExtraButtonsConfig = {
  items: EditorExtraButton[];
};

export type SimpleFileConfig = {
  allowedTypes: string[];
};

export type VideoConfig = ImageConfig;
export type CropConfig = CropImageAreaFigure;
