import React, { FC, PropsWithChildren, useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getGroupDetailedShortStorage } from '@vkph/common/store';
import { GroupId } from '@vkph/common/types/models';
import { declension, employeeDeclension, getRoutePath, RouteNames } from '@vkph/common/utils';
import {
  getNumberSizeAvatar,
  UiAvatar,
  UiEmpty,
  UiFlex,
  UiIcon,
  UiSkeleton,
  UiTooltip,
  UiTooltipProps,
  UiTypography,
  usePaddingStyle,
  useSpace,
  useTheme,
} from '@vkph/ui';
import { variables } from '@vkph/ui/providers/theme';
import AvatarUsersSvg from '@vkph/ui/svg/avatar-users.svg';
import CaseSvg from '@vkph/ui/svg/case.svg';

import { RouterLink } from '../router-link';

interface Props extends PropsWithChildren<Omit<UiTooltipProps, 'title'>> {
  groupId: GroupId;
}

export const GroupInfoTooltip: FC<Props> = (props) => {
  const { groupId, children, ...rest } = props;
  const [isOpen, setOpen] = useState(false);
  const [{ variables: themeVariables }] = useTheme();
  const { spaceXS, spaceM, spaceL } = useSpace();
  const paddingStyle = usePaddingStyle([spaceL]);
  const groupDetailedShortStorage = useMemo(getGroupDetailedShortStorage, []);

  const {
    data: groupDetailedShortData,
    loading: isGroupDetailedShortLoading,
    error: groupDetailedShortError,
  } = useAbstractStorage(groupDetailedShortStorage.storage, {
    autoFetchAndRefetch: isOpen,
    autoFetchParams: { groupId },
  });

  const avatarSize = useMemo(() => getNumberSizeAvatar('extraLarge'), []);

  const TooltipTitle: FC = () => {
    if (isGroupDetailedShortLoading) {
      return (
        <UiFlex gap={spaceM}>
          <UiSkeleton loading circle width={avatarSize} height={avatarSize} />
          <UiSkeleton loading width={100} height={20} />
        </UiFlex>
      );
    }

    if (groupDetailedShortError) {
      return (
        <UiEmpty.Feed
          emptyPadding
          emptyMessage={{ header: 'Произошла ошибка при загрузке информации о группе' }}
        />
      );
    }

    if (groupDetailedShortData) {
      const { id, name, organization, attributes, fileStorageImage } = groupDetailedShortData;
      const membersCount = attributes?.count || 0;

      return (
        <UiFlex gap={spaceM}>
          <UiAvatar size={avatarSize} src={fileStorageImage?.file || <AvatarUsersSvg />} />
          <UiFlex vertical align="start" gap={spaceXS}>
            <RouterLink to={getRoutePath(RouteNames.Structure, { id })}>
              <UiTypography.Title level={5} bottom={0}>
                {name}
              </UiTypography.Title>
            </RouterLink>
            {organization && (
              <UiTypography.Footnote style={{ color: themeVariables.colorTextTertiary }}>
                {organization.name}
              </UiTypography.Footnote>
            )}
            <UiTypography.Footnote style={{ color: themeVariables.colorTextTertiary }}>
              {membersCount} {declension(membersCount, employeeDeclension)}
            </UiTypography.Footnote>
            {organization && (
              <RouterLink
                to={getRoutePath(RouteNames.Structure, { id: organization.id })}
                style={{ marginTop: spaceXS }}
              >
                <UiFlex align="center" gap={spaceXS}>
                  <UiIcon
                    component={CaseSvg}
                    width={20}
                    height={20}
                    color={variables.themePalette.colorIcon}
                  />
                  <UiTypography.Text>{organization.name}</UiTypography.Text>
                </UiFlex>
              </RouterLink>
            )}
          </UiFlex>
        </UiFlex>
      );
    }

    return null;
  };

  return (
    <UiTooltip
      title={<TooltipTitle />}
      open={isOpen}
      onOpenChange={setOpen}
      overlayInnerStyle={paddingStyle}
      {...rest}
    >
      {children}
    </UiTooltip>
  );
};
