import { UiCell } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { ReactNode, useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage, UseAbstractStorageParams } from '@vkph/common/hooks';
import { AutoCompleteListStorage, BaseDataType, OptionModel, UiOptionDataExtended } from '@vkph/common/types';
import { SearchParams, UserIdModel, UserModel } from '@vkph/common/types/models';
import { getRoutePath, RouteNames } from '@vkph/common/utils';

import { MultiSelectField, MultiSelectFieldProps } from '../multiselect-field';
import { UiUser } from '../user';
import { UserAvatar } from '../user-avatar';

interface UserDateType extends BaseDataType, Pick<UserModel, 'isActive'> {
  avatar?: ReactNode;
}

type MultiSelectUserFieldBaseParam = Pick<SearchParams, 'search'>;

type UserOptions = OptionModel<UserIdModel, UserDateType>;

interface MultiSelectUserFieldProps<SP>
  extends Omit<MultiSelectFieldProps, 'ItemContent' | 'options' | 'onSearch'>,
    UseAbstractStorageParams<UiOptionDataExtended<UserModel>[], UiOptionDataExtended<UserModel>[], SP> {
  storage: AutoCompleteListStorage;
  normalizeQueryParam?: (search: string) => SP;
}

export const MultiSelectUserField = <SP extends MultiSelectUserFieldBaseParam>(
  props: MultiSelectUserFieldProps<SP>,
) => {
  const {
    storage,
    autoFetchParams = { isActive: true, skipEmptyName: true },
    autoFetchAndRefetch,
    resetStoreOnUnmount = true,
    cancelPendingRequestOnUnmount = true,
    normalizeQueryParam = (search) => ({ search }),
    ...restProps
  } = props;
  const [searchQuery, setSearchQuery] = useState('');

  const { fetchFx: fetchList, loading: isListLoading } = useAbstractStorage(storage, {
    autoFetchAndRefetch,
    cancelPendingRequestOnUnmount,
    autoFetchParams,
    resetStoreOnUnmount,
    resetStoreOnChangeParams: { searchQuery },
  });

  const onUpdate = (search: string) => fetchList({ ...normalizeQueryParam(search), ...autoFetchParams });

  const onSearchProfileListDebounced = useDebouncedCallback(onUpdate, 500);
  const onSearchProfileList = useCallback(
    (newSearchQuery: string) => {
      setSearchQuery(newSearchQuery);
      onSearchProfileListDebounced(newSearchQuery);
    },
    [onSearchProfileListDebounced],
  );

  const options = useStoreMap(storage.store, ({ data }) => {
    return data.map((option) => {
      return {
        value: option?.value,
        data: {
          avatar: option?.avatar,
          selectedLabel: option?.label,
          label: (
            <UiCell
              title={option?.label}
              avatar={<UserAvatar isActive={option?.data?.isActive} size="extraSmall" src={option?.avatar} />}
            />
          ),
        },
      };
    });
  });

  const renderUserItem = (itemContentProps: UserOptions) => {
    const {
      value,
      data: { avatar: avatarProp, isActive, selectedLabel },
    } = itemContentProps;
    const avatar = typeof avatarProp === 'string' ? { src: avatarProp } : { icon: avatarProp };

    return (
      <UiUser.Info
        titleProps={{ strong: false }}
        to={getRoutePath(RouteNames.Profile, { id: value })}
        title={selectedLabel}
        avatarProps={{ ...avatar, size: 'extraSmall' }}
        isActive={isActive}
      />
    );
  };

  return (
    <MultiSelectField
      options={options}
      ItemContent={renderUserItem}
      onSearch={onSearchProfileList}
      loading={isListLoading}
      {...restProps}
    />
  );
};
