import {
  DictionaryCellValue,
  FileCellValue,
  ListCellValues,
  ListColumnFieldType,
  UserCellValue,
} from '@vkph/common/types/models';
import { getFormattedDate } from '@vkph/common/utils';

import { BasicDynamicTableCellContentProps, DynamicTableCellContentProps } from './types';

export const isCellOfType = <Type extends ListCellValues>(
  props: BasicDynamicTableCellContentProps,
  expectedType: ListColumnFieldType,
): props is BasicDynamicTableCellContentProps<Type> => props.fieldType === expectedType;

export interface GetGroupNameByTypeParams
  extends Pick<DynamicTableCellContentProps, 'value' | 'fieldType' | 'fieldOptions'> {
  defaultName: string;
}

export const getGroupNameByType = (cellData: GetGroupNameByTypeParams) => {
  if (cellData.value === undefined) {
    return cellData.defaultName;
  }

  if (
    isCellOfType<UserCellValue>(cellData, ListColumnFieldType.User) ||
    isCellOfType<FileCellValue>(cellData, ListColumnFieldType.File)
  ) {
    return cellData.value.name;
  }

  if (isCellOfType<string>(cellData, ListColumnFieldType.Datetime)) {
    const { onlyDate } = cellData.fieldOptions || {};

    return getFormattedDate(new Date(cellData.value), onlyDate ? 'date' : 'dateTime');
  }

  if (isCellOfType<DictionaryCellValue[]>(cellData, ListColumnFieldType.Dictionary)) {
    return cellData.value.map(({ label }) => label).join(', ');
  }

  if (isCellOfType<string[]>(cellData, ListColumnFieldType.Enum)) {
    return cellData.value.join(', ');
  }

  return cellData.defaultName;
};
