import { Rule, UiForm, UiInput, UiPopover, UiTooltip, useToggle } from '@vkph/ui';
import React, { FC, useEffect, useMemo } from 'react';

import { getMaxLengthRule } from '@vkph/common/utils';

import { CellInput } from '../../../cell-input';
import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<string>;

export const TextCell: FC<Props> = (props) => {
  const {
    value,
    containerRef,
    cellNamePath,
    onCellBlur,
    fieldOptions,
    inputSuffix,
    readOnly,
    rules: basicRules,
  } = props;
  const { maxLength } = fieldOptions;

  const [isEdit, setIsEdit] = useToggle([false, true]);
  const form = UiForm.useFormInstance();

  const rules = useMemo<Rule[]>(() => {
    const result: Rule[] = basicRules || [];

    if (maxLength) {
      result.push(getMaxLengthRule(maxLength));
    }

    return result;
  }, [maxLength, basicRules]);

  const onBlur = () => {
    const val = form.getFieldValue(cellNamePath);

    onCellBlur?.(val);
  };

  const onSave = () => {
    form
      .validateFields([cellNamePath])
      .then(() => {
        setIsEdit(false);
        onBlur();
      })
      .catch(() => false);
  };

  const onFocus = () => {
    setIsEdit(true);
  };

  useEffect(() => {
    form.setFieldValue(cellNamePath, value);
  }, [value]);

  if (isEdit) {
    return (
      <UiPopover
        content={
          <UiForm.Item noStyle name={cellNamePath} rules={rules}>
            <UiInput.TextArea
              autoFocus
              onBlur={onSave}
              onPressEnter={onSave}
              style={{ width: '460px' }}
              autoSize={{ minRows: 2, maxRows: 7 }}
              maxLength={maxLength}
            />
          </UiForm.Item>
        }
        open={isEdit}
        arrow={false}
        autoAdjustOverflow={false}
        align={{ points: ['tl', 'tl'], offset: [0, 0] }}
        getPopupContainer={() => containerRef?.current || document.body}
        overlayInnerStyle={{ padding: 0 }}
      />
    );
  }

  return (
    <UiTooltip placement="top" title={value}>
      <UiForm.Item style={{ marginBottom: 0 }} name={cellNamePath} rules={rules}>
        <CellInput
          value={value}
          onFocus={readOnly ? undefined : onFocus}
          placeholder={readOnly ? undefined : 'Добавить текст'}
          suffix={inputSuffix}
          readOnly={readOnly}
        />
      </UiForm.Item>
    </UiTooltip>
  );
};
