import React, { FC, Fragment, PropsWithChildren, ReactNode } from 'react';

import { PostTextExtendedModel } from '@vkph/common/types/models';
import { parseTextPostContent } from '@vkph/common/utils';
import { UiButton, UiButtonDecoratorProps, UiTypography } from '@vkph/ui';

import { Editor } from '../../../editor';

export type PostBodyMarkupProps = {
  post: PostTextExtendedModel;
  onClick?: UiButtonDecoratorProps['onClick'];
  extra?: ReactNode;
};

export const PostBodyMarkup: FC<PostBodyMarkupProps> = (props) => {
  const { post, extra, onClick } = props;
  const { title } = post;
  const parsedTextContent = parseTextPostContent(post);

  const Wrapper: FC<PropsWithChildren> = (prop) => {
    if (onClick) {
      return <UiButton.Decorator style={{ display: 'inherit' }} onClick={onClick} {...prop} />;
    }

    return <Fragment {...prop} />;
  };

  return (
    <Wrapper>
      {title && <UiTypography.Title level={4}>{title}</UiTypography.Title>}
      {extra}
      <Editor.Viewer html={parsedTextContent} />
    </Wrapper>
  );
};
