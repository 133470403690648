// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._764e92cf_column-separator{height:42px;background:repeating-linear-gradient(-45deg, transparent 0, var(--default-themeVariables-colorStroke), transparent 2px, transparent 6px)}._764e92cf_column-separator_rotate-svg svg{transform:rotate(180deg)}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/calendar-appearance/column/separator/ColumnSeparator.scss"],"names":[],"mappings":"AACA,4BACE,WAAA,CACA,wIAAA,CASE,2CACE,wBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.column-separator {\n  height: 42px;\n  background: repeating-linear-gradient(\n    -45deg,\n    transparent 0,\n    $colorStroke,\n    transparent 2px,\n    transparent 6px\n  );\n\n  &_rotate-svg {\n    svg {\n      transform: rotate(180deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column-separator": "_764e92cf_column-separator",
	"columnSeparator": "_764e92cf_column-separator",
	"column-separator_rotate-svg": "_764e92cf_column-separator_rotate-svg",
	"columnSeparator_rotateSvg": "_764e92cf_column-separator_rotate-svg"
};
export default ___CSS_LOADER_EXPORT___;
