import { UiIcon, UiCard, UiImage, UiFlex, UiImageProps, UiTypography } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { BaseFieldParams } from '@vkph/common/types/api';
import { ServiceTypes } from '@vkph/common/types/models';
import { getFormattedDateRange } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import ConferenceSvg from '@vkph/ui/svg/conference.svg';
import ContactPhoneSvg from '@vkph/ui/svg/contact-phone.svg';
import FlipChartSvg from '@vkph/ui/svg/flipchart.svg';
import TvSvg from '@vkph/ui/svg/tv.svg';

import styles from './MeetingRoom.scss';
import { MeetingRoomCount } from './count/MeetingRoomCount';
import { MeetingRoomInfo } from './info/MeetingRoomInfo';
import { MeetingRoomSkeleton } from './skeleton/MeetingRoomSkeleton';

type MeetingPropertiesType = ServiceTypes | 'phone';

type MeetingColor = 'primary' | 'secondary' | 'tertiary';

type MeetingProperties = Array<MeetingPropertiesType>;

type MeetingRoomProps = {
  type: MeetingColor;
  src: UiImageProps['src'];
  properties?: MeetingProperties;
  onClick?: () => void;
  time?: Pick<BaseFieldParams, 'since' | 'till'>;
};

const MeetingRoomServices: Record<MeetingPropertiesType, SvgrComponent> = {
  video: FlipChartSvg,
  board: TvSvg,
  display: ConferenceSvg,
  phone: ContactPhoneSvg,
};

type MeetingRoomComposition = {
  Count: typeof MeetingRoomCount;
  Info: typeof MeetingRoomInfo;
  Skeleton: typeof MeetingRoomSkeleton;
};

type MeetingRoomType = FC<PropsWithChildren<MeetingRoomProps>> & MeetingRoomComposition;

export const MeetingRoom: MeetingRoomType = (props) => {
  const { children, src, properties, type, onClick, time } = props;
  const { spaceM, space2XS } = useSpace();

  const isActive = (color: MeetingColor) => type === color;
  const classNameStyles = classNames(styles.meetingRoom, {
    [styles.meetingRoom__tertiary]: isActive('tertiary'),
    [styles.meetingRoom__secondary]: isActive('secondary'),
    [styles.meetingRoom__primary]: isActive('primary'),
  });

  return (
    <UiCard size="default" className={classNameStyles} onClick={onClick} emptyPadding bordered hoverable>
      <UiCard.Content>
        <UiFlex gap={spaceM}>
          <UiImage src={src} height={96} width={96} />
          <UiFlex vertical justify="space-between">
            {time?.since && time?.till && (
              <UiTypography.Title level={5}>
                {getFormattedDateRange(time.since, time.till, 'time')}
              </UiTypography.Title>
            )}
            <UiFlex gap={space2XS} vertical>
              {children}
            </UiFlex>
            <UiFlex gap={spaceM}>
              {properties?.map((property) => (
                <UiIcon.Label
                  key={property}
                  component={MeetingRoomServices[property]}
                  width={20}
                  height={20}
                />
              ))}
            </UiFlex>
          </UiFlex>
        </UiFlex>
      </UiCard.Content>
    </UiCard>
  );
};

MeetingRoom.Count = MeetingRoomCount;
MeetingRoom.Info = MeetingRoomInfo;
MeetingRoom.Skeleton = MeetingRoomSkeleton;
