import { UiCard, UiCheckbox, UiCheckboxGroupProps, UiCheckboxValueType, UiEmpty } from '@vkph/ui';
import React, { FC, Fragment, PropsWithChildren, ReactNode, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getNewsFeedStorage, NewsFeedListParams } from '@vkph/common/store/news';
import { NewsArticle } from '@vkph/common/types/models';
import { createArrayMock } from '@vkph/common/utils';
import FiltersSVG from '@vkph/ui/svg/filters.svg';

import { NewsListItem } from './item/NewsListItem';
import { NewsListItemLoading } from './loading/NewsListItemLoading';

type NewsListComposition = {
  Item: typeof NewsListItem;
};

type Props = {
  title: React.ReactNode;
  renderItem: (item: NewsArticle) => ReactNode;
  fetchParams?: Partial<NewsFeedListParams>;
  filter?: {
    value: UiCheckboxValueType[];
    items: UiCheckboxGroupProps['options'];
    onChange: (values: UiCheckboxValueType[]) => void;
  };
};

const MAX_NEWS_COUNTS = 6;
const MOCKS_NEWS_COUNTS = 4;
const newsDataMocks = createArrayMock(MOCKS_NEWS_COUNTS, (_, key) => <NewsListItemLoading key={key} />);

export const NewsList: FC<PropsWithChildren<Props>> & NewsListComposition = (props) => {
  const { title, children, renderItem, fetchParams, filter } = props;
  const newsFeedStorage = useMemo(() => getNewsFeedStorage({ shouldAppendData: false }), []);

  const { data: newsFeed, loading } = useAbstractStorage(newsFeedStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      pageSize: MAX_NEWS_COUNTS,
      ...(fetchParams || {}),
    },
    cancelPendingRequestOnUnmount: true,
  });

  const isFeedEmpty = !loading && !newsFeed?.length;

  return (
    <UiCard size="default" emptyPadding>
      <UiCard.Header>
        <UiCard.Header.Title style={{ width: '100%' }}>{title}</UiCard.Header.Title>
        {filter && Boolean(filter.items?.length) && (
          <UiCheckbox.Menu {...filter} icon={FiltersSVG} placement="bottom" />
        )}
      </UiCard.Header>
      <UiCard.Content basePadding style={{ paddingTop: 0 }}>
        {loading && newsDataMocks}
        {!loading && (
          <>
            {newsFeed.map((item) => (
              <Fragment key={item.id}>{renderItem(item)}</Fragment>
            ))}
            {children}
          </>
        )}
        {isFeedEmpty && <UiEmpty.Feed emptyMessage={{ header: 'Нет новостей' }} />}
      </UiCard.Content>
    </UiCard>
  );
};

NewsList.Item = NewsListItem;
