import { UiAvatar, UiAvatarProps } from '@vkph/ui';
import React, { FC } from 'react';

import UserBlockedAvatarPlaceholderSvg from '@vkph/ui/svg/user-blocked-avatar-placeholder.svg';

export interface UserAvatarProps extends UiAvatarProps {
  isActive?: boolean;
}

export const UserAvatar: FC<UserAvatarProps> = (props) => {
  const { size, icon, src, isActive, ...avatarProps } = props;

  const iconProps =
    isActive || isActive === undefined
      ? { icon, src }
      : { icon: <UserBlockedAvatarPlaceholderSvg height="100%" width="100%" />, disabled: true };

  return <UiAvatar {...iconProps} size={size} {...avatarProps} />;
};
