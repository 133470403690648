import classNames from 'classnames';
import { format, differenceInMinutes } from 'date-fns';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAbstractStorage, useLongreadLink } from '@vkph/common/hooks';
import { GetCalendarEventsStorage } from '@vkph/common/store/calendar';
import { openGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';
import { LongreadTypes } from '@vkph/common/types';
import { CalendarEventModel, CalendarEventType, CalendarItemStatus } from '@vkph/common/types/models';
import { UiTruncateMarkup, UiTypography } from '@vkph/ui';

import { AppearanceTooltip } from '../../tooltip/AppearanceTooltip';
import styles from './MonthEvent.scss';

type MonthEventProps = {
  event: CalendarEventModel;
  isLastWeekDay?: boolean;
  columnDate?: Date;
  isSameTime?: boolean;
  isFirstDayEvent?: boolean;
  isLastDayEvent?: boolean;
  eventsStorage: GetCalendarEventsStorage;
};

export const MonthEvent: FC<MonthEventProps> = (props) => {
  const { event, isSameTime, isLastDayEvent, isLastWeekDay, isFirstDayEvent, columnDate, eventsStorage } =
    props;
  const { storage } = eventsStorage;
  const { refetchWithLastParams } = useAbstractStorage(storage);

  const navigate = useNavigate();
  const { to: linkToEventLongread } = useLongreadLink({ type: LongreadTypes.Events, id: event.id });

  const { since, till, summary, eventType, isUserParticipate } = event;
  const sinceDate = new Date(since);
  const tillDate = new Date(till);
  const isEventPassed = differenceInMinutes(Date.now(), tillDate) >= 0;

  const getStatus = () => {
    if (isEventPassed) return CalendarItemStatus.Past;
    if (isUserParticipate) return CalendarItemStatus.Future;
    return CalendarItemStatus.Maybe;
  };

  const timeMask = 'HH:mm';
  const timeStartString = format(sinceDate, timeMask);

  const openEventClickHandler: React.MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();

    if (eventType === CalendarEventType.Activity) {
      navigate(linkToEventLongread);
    } else {
      openGlobalModal(GlobalModalNames.CalendarEventDetail, { event, onSuccess: refetchWithLastParams });
    }
  };

  const contentTitleEvent = (
    <UiTypography.Text className={styles.monthEvent__title} strong>
      <UiTruncateMarkup truncate lines={1} className={styles.monthEvent__contentContainer}>
        <span className={styles.monthEvent__time}>{timeStartString}</span>
        {summary}
      </UiTruncateMarkup>
    </UiTypography.Text>
  );

  return (
    <AppearanceTooltip
      isLastWeekDay={isLastWeekDay}
      event={event}
      columnDate={columnDate}
      eventsStorage={eventsStorage}
    >
      <button
        className={classNames(styles.monthEvent, `${styles.monthEvent}_${event.eventType}-${getStatus()}`, {
          [styles.monthEvent_same]: isSameTime && !isLastDayEvent && !isLastWeekDay,
        })}
        type="button"
        onClick={openEventClickHandler}
      >
        {isFirstDayEvent && isSameTime && contentTitleEvent}
        {!isSameTime && contentTitleEvent}
      </button>
    </AppearanceTooltip>
  );
};
