import { UiSpace, UiTruncateMarkup, UiTypography, useSpace } from '@vkph/ui';
import React, { CSSProperties, FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { useLongreadLink } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types';
import { NewsArticle, PostTypes } from '@vkph/common/types/models';
import { getFormattedDate } from '@vkph/common/utils';

import { Post } from '../../post';
import styles from './NewsListItem.scss';

type Props = {
  newsArticle: NewsArticle;
  linkProps?: LinkProps;
};

export const NewsListItem: FC<Props> = (props) => {
  const { newsArticle, linkProps } = props;
  const { space2XS } = useSpace();
  const imageStyle: CSSProperties = { borderRadius: space2XS / 2 };
  const { id, type, cover, title, publishedAt } = newsArticle;
  const { to, state } = useLongreadLink({ type: LongreadTypes.News, id, newsType: type || PostTypes.Simple });

  return (
    <Link className={styles.newsListItem} to={to} state={state} {...linkProps}>
      <UiSpace align="start" style={{ marginRight: 16 }}>
        <Post.Cover src={cover} width={90} style={imageStyle} wrapperStyle={imageStyle} />
      </UiSpace>

      <div className={styles.newsListItem__body}>
        <UiTypography.Text className={styles.newsListItem__title}>
          <UiTruncateMarkup truncate lines={3}>
            {title}
          </UiTruncateMarkup>
        </UiTypography.Text>

        <UiTypography.Text className={styles.newsListItem__date}>
          {getFormattedDate(publishedAt, 'dateTime')}
        </UiTypography.Text>
      </div>
    </Link>
  );
};
