import { UiButton, UiForm, UiModal, getModalStepsForSingleTitle } from '@vkph/ui';
import React, { FC } from 'react';

import { DictionaryCellValue, DictionaryType, ListColumnId, ListRowValues } from '@vkph/common/types/models';

import { DynamicTableCellContentProps } from '../../types';
import { DictionaryDepartments } from '../departments/DictionaryDepartments';
import { DictionaryOrganizations } from '../organizations/DictionaryOrganizations';
import { DictionaryPosition } from '../positions/DictionaryPosition';

type DictionaryFormValue = { label: string; value: string };
type FormValues = ListRowValues & Record<ListColumnId, DictionaryFormValue[]>;

interface Props extends Pick<DynamicTableCellContentProps<DictionaryCellValue[] | undefined>, 'dataIndex'> {
  dictionaryType: DictionaryType;
  values: DictionaryFormValue[];
  onSave: (formValues: FormValues) => void;
  onClose: () => void;
}

export const DictionaryCellForm: FC<Props> = (props) => {
  const { dataIndex, values, dictionaryType, onSave, onClose } = props;
  const [form] = UiForm.useForm<FormValues>();

  const onReset = () => {
    form.resetFields();
    onClose();
  };

  const onFinish = (formValues: FormValues) => {
    onSave(formValues);
    onClose();
  };

  const content = {
    [DictionaryType.Position]: <DictionaryPosition dataIndex={dataIndex} />,
    [DictionaryType.Department]: <DictionaryDepartments dataIndex={dataIndex} />,
    [DictionaryType.Organization]: <DictionaryOrganizations dataIndex={dataIndex} />,
  };

  const initialValues = {
    [dataIndex]: values,
  };

  return (
    <UiForm form={form} initialValues={initialValues} onFinish={onFinish}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Выбрать из справочника')} />
      </UiModal.Header>
      <UiModal.Content basePadding>{content[dictionaryType]}</UiModal.Content>
      <UiModal.Footer>
        <UiModal.Footer.Buttons>
          <UiForm.Item noStyle shouldUpdate>
            {({ isFieldsTouched }) => (
              <UiButton type="primary" size="large" disabled={!isFieldsTouched()} onClick={form.submit}>
                Выбрать
              </UiButton>
            )}
          </UiForm.Item>
          <UiButton type="tertiary" size="large" onClick={onReset}>
            Отменить выбор
          </UiButton>
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </UiForm>
  );
};
