import {
  UiFlex,
  UiForm,
  UiModal,
  UiModalTypes,
  UiTooltip,
  UiTypography,
  useSpace,
  useToggle,
} from '@vkph/ui';
import React, { FC, useEffect } from 'react';

import { DictionaryCellValue, ListColumnId, ListRowValues } from '@vkph/common/types/models';
import { declension, generateSelectOptions, notesDeclension } from '@vkph/common/utils';

import { CellInput } from '../../../cell-input';
import { DynamicTableCellContentProps } from '../types';
import { DictionaryCellForm } from './dictionary-cell-form/DictionaryCellForm';

type Props = DynamicTableCellContentProps<DictionaryCellValue[]>;

type DictionaryFormValue = { label: string; value: string };

export const DictionaryCell: FC<Props> = (props) => {
  const {
    inputSuffix,
    dataIndex,
    fieldOptions: { dictionaryType },
    readOnly,
    value,
    cellNamePath,
    onCellBlur,
    rules,
  } = props;
  const { spaceXL } = useSpace();
  const form = UiForm.useFormInstance();
  const dictionaries = UiForm.useWatch<DictionaryCellValue[]>(cellNamePath, form) || [];
  const [isOpenModal, toggleModalOpen] = useToggle([false, true]);
  const values = generateSelectOptions(dictionaries, { valuePath: 'value', labelPath: 'label' });

  const onSave = (formValues: ListRowValues & Record<ListColumnId, DictionaryFormValue[]>) => {
    if (dictionaryType) {
      const selectedValue: DictionaryCellValue[] = formValues[dataIndex].map((item) => {
        return {
          type: dictionaryType,
          label: item.label,
          value: item.value,
        };
      });

      form.setFieldValue(cellNamePath, selectedValue);

      onCellBlur?.(selectedValue);
    }
  };

  const getButtonText = () => {
    if (dictionaries.length === 1) {
      return dictionaries[0].label;
    }

    if (dictionaries.length > 1) {
      return `Выбрано ${dictionaries.length} ${declension(dictionaries.length, notesDeclension)}`;
    }

    return 'Добавить запись';
  };

  const openModal = () => {
    toggleModalOpen();
  };

  const isBlueColorText = dictionaries.length === 0 || dictionaries.length > 1;

  const getTooltipText = () =>
    dictionaries.length > 1 && (
      <>
        {dictionaries.map((item) => (
          <UiTypography.Paragraph key={item.value}>{item.label}</UiTypography.Paragraph>
        ))}
      </>
    );

  useEffect(() => {
    form.setFieldValue(cellNamePath, value);
  }, [value]);

  return (
    <>
      <UiModal type={UiModalTypes.Medium} onClose={toggleModalOpen} isOpen={isOpenModal}>
        {dictionaryType && (
          <DictionaryCellForm
            dataIndex={dataIndex}
            dictionaryType={dictionaryType}
            values={values}
            onSave={onSave}
            onClose={() => toggleModalOpen(false)}
          />
        )}
      </UiModal>

      <UiTooltip shift={spaceXL} placement="top" title={getTooltipText()}>
        <UiForm.Item name={cellNamePath} rules={rules}>
          <UiFlex style={{ position: 'relative' }}>
            <CellInput.Button
              onClick={readOnly ? undefined : openModal}
              readOnly={readOnly}
              isEmpty={isBlueColorText}
              suffix={inputSuffix}
            >
              {getButtonText()}
            </CellInput.Button>
          </UiFlex>
        </UiForm.Item>
      </UiTooltip>
    </>
  );
};
