import {
  UiFile,
  UiFileDirection,
  UiFlex,
  UiTooltip,
  UiTruncateMarkup,
  UiTypography,
  UiTypographySymbolName,
  getFormattedFileSize,
  notification,
  useSpace,
  useToggle,
  UiForm,
} from '@vkph/ui';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getFileStorageFolderInfoStorage } from '@vkph/common/store';
import { isFileStorageSitesContext } from '@vkph/common/types';
import { FileCellValue } from '@vkph/common/types/models';
import { getFormattedDate, getRoutePath, RouteNames } from '@vkph/common/utils';

import { CellInput } from '../../../cell-input';
import { DynamicTableCellContentProps } from '../types';
import { FileCellForm } from './file-cell-form/FileCellForm';

type Props = DynamicTableCellContentProps<FileCellValue | undefined>;

export const FileCell: FC<Props> = (props) => {
  const { value, inputSuffix, readOnly, fieldOptions, onCellBlur, cellNamePath, rules } = props;
  const { folderId } = fieldOptions;

  const form = UiForm.useFormInstance();
  const file = UiForm.useWatch<FileCellValue>(cellNamePath, form);
  const navigate = useNavigate();
  const { spaceXL } = useSpace();
  const [isOpen, toggleIsOpen] = useToggle([false, true]);
  const [selectedFile, setSelectedFile] = useState<FileCellValue | undefined>(file);
  const [isShowUploadArea, toggleIsShowUploadArea] = useToggle([false, true]);

  const { storage: fileStorageFolderInfoStorage } = useMemo(getFileStorageFolderInfoStorage, [
    isShowUploadArea,
  ]);

  const { data: fileStorageFolderInfoData, fetchFx: fetchFileStorageFolderInfo } = useAbstractStorage(
    fileStorageFolderInfoStorage,
    {
      autoFetchAndRefetch: !readOnly && isOpen && Boolean(folderId),
      autoFetchParams: {
        id: folderId || '',
      },
      resetStoreOnUnmount: true,
      cancelPendingRequestOnUnmount: true,
    },
  );

  const closeModal = () => {
    toggleIsOpen();
  };

  const onSelect = useCallback((fileToSelect: FileCellValue) => {
    setSelectedFile(fileToSelect);
  }, []);

  const hasValue = Boolean(file && file?.id);

  const onUpdateCell = () => {
    if (selectedFile) {
      form.setFieldValue(cellNamePath, selectedFile);
      onCellBlur?.(selectedFile);
      closeModal();
    }
  };

  const onUploadFile = () => {
    toggleIsShowUploadArea();
  };

  const navigateToFile = async () => {
    if (folderId && file?.id) {
      try {
        const { context, slug } = await fetchFileStorageFolderInfo({ id: folderId });

        if (isFileStorageSitesContext(context) && slug) {
          navigate(
            getRoutePath(RouteNames.SiteFile, {
              slug: context.siteSlug,
              serviceSlug: slug,
              fileId: file.id,
            }),
          );
        } else {
          notification.error({ message: 'Не удалось открыть информацию о файле' });
        }
      } catch (e) {
        notification.error({ message: 'Не удалось открыть файл' });
      }
    }
  };

  const isFileInfo = file?.size && file?.createdAt;

  useEffect(() => {
    form.setFieldValue(cellNamePath, value);
  }, [value]);

  return (
    <>
      {fileStorageFolderInfoData && folderId && (
        <FileCellForm
          isOpen={isOpen}
          rootId={folderId}
          selectedKey={selectedFile?.id || null}
          title={fileStorageFolderInfoData.name}
          onUploadFile={onUploadFile}
          closeModal={closeModal}
          onSelect={onSelect}
          onFinish={onUpdateCell}
        />
      )}
      <UiTooltip title={file?.name} placement="top" shift={spaceXL}>
        <UiForm.Item name={cellNamePath} rules={rules}>
          <UiFlex style={{ position: 'relative' }}>
            <CellInput.Button
              onClick={readOnly ? navigateToFile : () => toggleIsOpen()}
              suffix={inputSuffix}
              isSmallPadding={hasValue}
              isEmpty={!hasValue}
            >
              {file && (
                <UiFile
                  direction={UiFileDirection.Horizontal}
                  title={
                    <UiTypography.Paragraph style={{ textAlign: 'left', marginBottom: 0 }} strong>
                      <UiTruncateMarkup truncate>{file.name}</UiTruncateMarkup>
                    </UiTypography.Paragraph>
                  }
                  subtitle={
                    <UiTypography.Text type="secondary">
                      {isFileInfo && (
                        <UiTruncateMarkup truncate>
                          {getFormattedFileSize(file.size)} •{getFormattedDate(file.createdAt)}
                        </UiTruncateMarkup>
                      )}
                      {!isFileInfo && <UiTypography.Symbol name={UiTypographySymbolName.EmDash} />}
                    </UiTypography.Text>
                  }
                  fileName={file.name}
                />
              )}
              {!file && 'Добавить файл'}
            </CellInput.Button>
          </UiFlex>
        </UiForm.Item>
      </UiTooltip>
    </>
  );
};
