import classNames from 'classnames';
import React, { FC, useContext } from 'react';

import { UiButton, UiButtonProps, UiFlex, UiInputProps, UiFormItemInputContext, useSpace } from '@vkph/ui';

import styles from '../CellInput.scss';

export interface CellInputButtonProps extends UiButtonProps, Pick<UiInputProps, 'suffix'> {
  isEmpty?: boolean;
  isSmallPadding?: boolean;
  readOnly?: boolean;
}

export const CellInputButton: FC<CellInputButtonProps> = (props) => {
  const { suffix, isEmpty, isSmallPadding, readOnly, className, ...rest } = props;
  const { space2XS } = useSpace();
  const { status: contextStatus } = useContext(UiFormItemInputContext);

  return (
    <UiFlex className={classNames(styles.uiCellInputContainer, className)} gap={space2XS}>
      <UiButton
        className={classNames(styles.uiCellInput, styles.uiCellInput__button, {
          [styles.uiCellInput__button_empty]: isEmpty,
          [styles.uiCellInput__button_smallPadding]: isSmallPadding,
          [styles.uiCellInput_readOnly]: readOnly,
          [styles.uiCellInput_error]: contextStatus === 'error',
        })}
        block
        {...rest}
      />
      {suffix && <span className={styles.uiCellInput__suffix}>{suffix}</span>}
    </UiFlex>
  );
};
