import { UiFlex, UiForm, UiIcon, useSpace } from '@vkph/ui';
import React, { FC, useEffect } from 'react';

import DropDownSvg from '@vkph/ui/svg/drop-down.svg';

import { CellInput } from '../../../cell-input';
import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<string[]>;

export const EnumCell: FC<Props> = (props) => {
  const { inputSuffix, cellNamePath, fieldOptions, value, readOnly, onCellBlur, rules } = props;
  const form = UiForm.useFormInstance();
  const { spaceXS } = useSpace();

  const onSave = () => {
    const val = form.getFieldValue(cellNamePath);

    onCellBlur?.(val);
  };

  useEffect(() => {
    form.setFieldValue(cellNamePath, value || null);
  }, [value]);

  return (
    <UiForm.Item style={{ marginBottom: 0 }} name={cellNamePath} rules={rules}>
      <CellInput.Select
        onBlur={readOnly ? undefined : onSave}
        onDeselect={readOnly ? undefined : onSave}
        suffixIcon={inputSuffix}
        readOnly={readOnly}
        mode="tags"
        placeholder={
          <UiFlex gap={spaceXS} align="center">
            Выбрать из списка
            <UiIcon component={DropDownSvg} width={20} height={20} />
          </UiFlex>
        }
        options={fieldOptions.choices?.map((choice) => ({
          value: choice,
          label: choice,
        }))}
      />
    </UiForm.Item>
  );
};
