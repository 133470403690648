import { UiCell, UiOptionDataExtended } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { useState } from 'react';

import { useAbstractStorage, UseAbstractStorageParams } from '@vkph/common/hooks';
import { useProfileStorage } from '@vkph/common/store/profile/hooks';
import { AutoCompleteListStorage } from '@vkph/common/types/autoCompleteList';
import { UserModel, SearchParams } from '@vkph/common/types/models';

import { SearchOptionComplete, SearchOptionCompleteType, SearchOptionItem } from '../search-option-complete';
import { UserAvatar } from '../user-avatar';

type SearchUserFieldBaseParam = Pick<SearchParams, 'search'>;

export interface SearchUserFieldOptionItem extends SearchOptionItem<Pick<UserModel, 'avatar' | 'isActive'>> {
  value: string;
}

interface SearchUserFieldProps<SP>
  extends Omit<SearchOptionCompleteType, 'value'>,
    UseAbstractStorageParams<UiOptionDataExtended<UserModel>[], UiOptionDataExtended<UserModel>[], SP> {
  value?: SearchUserFieldOptionItem;
  storage: AutoCompleteListStorage;
  normalizeQueryParam?: (search: string) => SP;
}

export const SearchUserField = <SP extends SearchUserFieldBaseParam>(props: SearchUserFieldProps<SP>) => {
  const {
    value,
    storage,
    autoFetchParams,
    autoFetchAndRefetch = true,
    resetStoreOnUnmount = true,
    cancelPendingRequestOnUnmount = true,
    normalizeQueryParam = (search) => ({ search }),
    ...restProps
  } = props;
  const [searchQuery, setSearchQuery] = useState('');

  const { fetchFx: fetchList, loading: isListPending } = useAbstractStorage(storage, {
    autoFetchAndRefetch,
    cancelPendingRequestOnUnmount,
    autoFetchParams,
    resetStoreOnUnmount,
    resetStoreOnChangeParams: { searchQuery },
  });

  const { data: profile } = useProfileStorage({
    id: value?.option && 'avatar' in value.option ? undefined : value?.value,
    deps: [value?.value, value?.option?.avatar],
  });

  const onUpdate = (query: string) => fetchList({ ...normalizeQueryParam(query), ...autoFetchParams });

  const options = useStoreMap(storage.store, ({ data }) => {
    return data.map((item) => ({
      value: item.value,
      label: (
        <UiCell
          title={item.label}
          avatar={<UserAvatar size="small" src={item?.data?.smallAvatar} isActive={item?.data?.isActive} />}
        />
      ),
      name: item.label,
      data: {
        avatar: item?.data?.smallAvatar,
      },
    }));
  });

  return (
    <SearchOptionComplete
      placeholder="Выбрать"
      size="large"
      options={options}
      value={value}
      searchQuery={searchQuery}
      onSearch={setSearchQuery}
      updateOptionListHandler={onUpdate}
      loading={isListPending}
      prefix={
        <UserAvatar
          size="extraSmall"
          src={value?.option?.avatar || profile?.smallAvatar}
          isActive={value?.option?.isActive || profile?.isActive}
        />
      }
      {...restProps}
    />
  );
};
