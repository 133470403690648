import { UiForm, UiTooltip, useSpace } from '@vkph/ui';
import React, { FC, useEffect } from 'react';

import { CellInput } from '../../../cell-input';
import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<string>;

const MAX_LENGTH = 21;

export const LinkCell: FC<Props> = (props) => {
  const { value, cellNamePath, readOnly, onCellBlur, inputSuffix, rules } = props;
  const form = UiForm.useFormInstance();
  const inputValue = UiForm.useWatch(cellNamePath, form) || '';
  const { spaceXL } = useSpace();

  const onSave = () => onCellBlur?.(inputValue);

  useEffect(() => {
    form.setFieldValue(cellNamePath, value);
  }, [value]);

  return (
    <UiTooltip shift={spaceXL} title={inputValue.length > MAX_LENGTH ? inputValue : null}>
      <UiForm.Item style={{ marginBottom: 0 }} name={cellNamePath} rules={rules}>
        <CellInput.Link
          onPressEnter={onSave}
          onBlur={readOnly ? undefined : onSave}
          placeholder={readOnly ? undefined : 'Добавить ссылку'}
          readOnly={readOnly}
          suffix={inputSuffix}
        />
      </UiForm.Item>
    </UiTooltip>
  );
};
