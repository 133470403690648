import { UiSkeleton } from '@vkph/ui';
import React, { FC, Suspense } from 'react';

import { FileStorageListEntryModel } from '@vkph/common/types/models';

import { FileStorageListItemSelect } from '..';
import { ActionsDropdownItem } from '../../actions-dropdown';
import { FileProps } from '../../file';

type FileStorageItemFunction = (item: FileStorageListEntryModel) => void;

export interface FileStorageListItemProps extends Pick<FileProps, 'direction'> {
  fileSelect?: FileStorageListItemSelect;
  onClick: FileStorageItemFunction;
  file: FileStorageListEntryModel;
  onFileSelect?: FileStorageItemFunction;
  getFileActions: (file: FileStorageListEntryModel) => ActionsDropdownItem[];
}

const FileStorageListItemInner = React.lazy(() => import('./inner'));

export const FileStorageListItem: FC<FileStorageListItemProps> = (props) => (
  <Suspense fallback={<UiSkeleton loading height={40} />}>
    <FileStorageListItemInner {...props} />
  </Suspense>
);
