import { UiFlex, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC, ReactNode } from 'react';

import { useSpace } from '@vkph/ui/hooks';

type InfoProps = {
  title: ReactNode;
  subTitle?: string;
};

export const MeetingRoomInfo: FC<InfoProps> = (props) => {
  const { title, subTitle } = props;
  const { space2XS } = useSpace();
  const isBaseTitle = typeof title === 'string';

  return (
    <UiFlex vertical gap={space2XS}>
      {!isBaseTitle && title}
      {isBaseTitle && (
        <UiTypography.Title level={5} bottom={0}>
          <UiTruncateMarkup truncate>{title}</UiTruncateMarkup>
        </UiTypography.Title>
      )}
      {subTitle && (
        <UiTypography.Footnote type="secondary">
          <UiTruncateMarkup truncate>{subTitle}</UiTruncateMarkup>
        </UiTypography.Footnote>
      )}
    </UiFlex>
  );
};
