import { Alignment } from '@ckeditor/ckeditor5-alignment';
import {
  Bold,
  Code,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
} from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { FontBackgroundColor, FontColor, FontSize } from '@ckeditor/ckeditor5-font';
import { Heading, HeadingConfig } from '@ckeditor/ckeditor5-heading';
import { Highlight } from '@ckeditor/ckeditor5-highlight';
import {
  AutoImage,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
} from '@ckeditor/ckeditor5-image';
import { ImageConfig } from '@ckeditor/ckeditor5-image/src/imageconfig';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { AutoLink, Link } from '@ckeditor/ckeditor5-link';
import { List, ListProperties } from '@ckeditor/ckeditor5-list';
import { AutoMediaEmbed, MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { SelectAll } from '@ckeditor/ckeditor5-select-all';
import { SpecialCharacters, SpecialCharactersEssentials } from '@ckeditor/ckeditor5-special-characters';
import { Table, TableCellProperties, TableProperties, TableToolbar } from '@ckeditor/ckeditor5-table';
import { Undo } from '@ckeditor/ckeditor5-undo';

import { ExtraButtonsPlugin } from '../plugins/extra-buttons';
import '../plugins/image-crop/augmentation';
import { ImageCropPlugin } from '../plugins/image-crop/ImageCropPlugin';
import { MentionPlugin } from '../plugins/mention';
import { Mention } from '../plugins/mention/lib';
import { SimpleFilePlugin } from '../plugins/simple-file';
import { UploadAdapterPlugin } from '../plugins/upload-adapter';
import {
  Video,
  VideoCaption,
  VideoResize,
  VideoStyle,
  VideoToolbar,
  VideoUpload,
} from '../plugins/video/lib';
import { CropConfig, ExtraButtonsConfig, SimpleFileConfig, VideoConfig } from '../types';
import { mergeEditorToolbar } from '../utils/merge-editor-toolbar';
import { editorConfigurationCrop } from './constants';
import { allEditorToolbars } from './toolbar-config';

const heading: HeadingConfig = {
  options: [
    {
      model: 'paragraph',
      title: 'Paragraph',
      class: 'ck-heading_paragraph',
    },
    {
      model: 'heading1',
      view: 'h1',
      title: 'Heading 1',
      class: 'ck-heading_heading1',
    },
    {
      model: 'heading2',
      view: 'h2',
      title: 'Heading 2',
      class: 'ck-heading_heading2',
    },
    {
      model: 'heading3',
      view: 'h3',
      title: 'Heading 3',
      class: 'ck-heading_heading3',
    },
    {
      model: 'heading4',
      view: 'h4',
      title: 'Heading 4',
      class: 'ck-heading_heading4',
    },
    {
      model: 'heading5',
      view: 'h5',
      title: 'Heading 5',
      class: 'ck-heading_heading5',
    },
  ],
};

export interface CkEditorConfig extends EditorConfig {
  crop: CropConfig;
  simpleFile: SimpleFileConfig;
  extraButtons?: ExtraButtonsConfig;
  video?: VideoConfig;
  image?: ImageConfig;
}

export const editorToolbarOneLineConfig: Partial<CkEditorConfig> = {
  toolbar: {
    shouldNotGroupWhenFull: false,
  },
};

export const editorConfiguration: CkEditorConfig = {
  placeholder: 'Начните вводить текст здесь...',
  plugins: [
    Alignment,
    Essentials,
    SelectAll,
    Indent,
    IndentBlock,
    BlockQuote,
    FontSize,
    FontColor,
    FontBackgroundColor,
    Mention,
    Heading,
    Paragraph,
    Bold,
    Code,
    CodeBlock,
    Italic,
    Strikethrough,
    Underline,
    Subscript,
    Superscript,
    Link,
    AutoLink,
    List,
    ListProperties,
    Image,
    ImageUpload,
    ImageStyle,
    ImageResize,
    ImageCaption,
    AutoImage,
    ImageToolbar,
    ImageTextAlternative,
    Undo,
    SpecialCharacters,
    SpecialCharactersEssentials,
    Table,
    TableToolbar,
    TableCellProperties,
    TableProperties,
    MediaEmbed,
    AutoMediaEmbed,
    Highlight,
    Video,
    VideoUpload,
    VideoStyle,
    VideoResize,
    VideoCaption,
    VideoToolbar,
  ],
  toolbar: {
    shouldNotGroupWhenFull: true,
    items: mergeEditorToolbar(allEditorToolbars),
  },
  extraPlugins: [UploadAdapterPlugin, SimpleFilePlugin, MentionPlugin, ImageCropPlugin, ExtraButtonsPlugin],
  crop: editorConfigurationCrop,
  image: {
    insert: {
      integrations: [],
      type: 'block',
    },
    resizeUnit: '%',
    toolbar: [
      'imageTextAlternative',
      'toggleImageCaption',
      '|',
      'imageStyle:wrapText',
      'imageStyle:breakText',
      '|',
      'imageResize',
    ],
    upload: {
      types: ['png', 'jpeg'],
    },
  },
  video: {
    resizeUnit: '%',
    toolbar: ['toggleVideoCaption', '|', 'videoStyle:wrapText', 'videoStyle:breakText', '|', 'videoResize'],
    upload: {
      types: ['mp4', 'wmv', 'avi', 'mkv', 'flv', '3gp', 'webm', 'ogg'],
    },
  },
  codeBlock: {
    languages: [{ language: 'plaintext', label: 'Plain text' }],
  },
  heading,
  indentBlock: {
    offset: 16,
    unit: 'px',
  },
  list: {
    properties: {
      styles: false,
    },
  },
  mediaEmbed: {
    previewsInData: true,
  },
  language: 'ru',
  simpleFile: {
    allowedTypes: ['.pdf', '.doc', '.docx', '.xls', '.xlsx'],
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
  },
};
