import React, { FC, useEffect, useMemo, useRef } from 'react';

import { regexpPatterns, validateMaxValue, validateMinValue } from '@vkph/common/utils';
import { Rule, UiForm, UiInputRef, UiTooltip, useSpace } from '@vkph/ui';

import { CellInput } from '../../../cell-input';
import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<number>;

const MAX_LENGTH = 21;

export const NumericCell: FC<Props> = (props) => {
  const { fieldOptions, cellNamePath, value, onCellBlur, inputSuffix, rules: basicRules, readOnly } = props;
  const { maxValue: max, minValue: min } = fieldOptions?.range || {};
  const form = UiForm.useFormInstance();
  const inputValue = value?.toString() || '';
  const inputRef = useRef<UiInputRef>(null);
  const { spaceXL } = useSpace();

  const rules = useMemo<Rule[]>(() => {
    const result: Rule[] = [
      {
        message: 'Пожалуйста, введите числовое значение',
        pattern: regexpPatterns.number,
      },
    ];

    if (basicRules) {
      result.push(...basicRules);
    }

    if (min) {
      result.push(validateMinValue(min, 'значение за пределами диапазона'));
    }

    if (max) {
      result.push(validateMaxValue(max, 'значение за пределами диапазона'));
    }

    return result;
  }, [min, max, basicRules]);

  const clearFocus = () => {
    inputRef.current?.blur();
  };

  const onBlur = () => {
    const val = Number(form.getFieldValue(cellNamePath));

    onCellBlur?.(val);
  };

  useEffect(() => {
    form.setFieldValue(cellNamePath, inputValue);
  }, [inputValue]);

  return (
    <UiTooltip placement="top" shift={spaceXL} title={inputValue.length > MAX_LENGTH ? value : null}>
      <UiForm.Item style={{ marginBottom: 0 }} name={cellNamePath} rules={rules}>
        <CellInput
          ref={inputRef}
          onPressEnter={readOnly ? undefined : clearFocus}
          onBlur={readOnly ? undefined : onBlur}
          placeholder={readOnly ? undefined : 'Добавить число'}
          suffix={inputSuffix}
          readOnly={readOnly}
        />
      </UiForm.Item>
    </UiTooltip>
  );
};
