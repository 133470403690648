import { UiCollapse, UiTypography, UiMenu, UiMenuItemType, UiCollapseProps } from '@vkph/ui';
import classnames from 'classnames';
import React, { useCallback, useMemo, useState, PropsWithChildren, FC } from 'react';

import { ActionsDropdown, ActionsDropdownItem } from '../../actions-dropdown';
import { SidebarItem, SidebarMenuItemComponent, SidebarMenuItem, SidebarType } from '../item/SidebarMenuItem';
import styles from './SidebarMenu.scss';

type SidebarMenuComposition = {
  Item: SidebarMenuItemComponent;
};

export type SidebarMenuProps = {
  title?: string | React.ReactNode;
  className?: string;
  collapsible?: boolean;
  value: SidebarItem;
  onSelect?: (value: string) => void;
  actions?: ActionsDropdownItem[];
  items: SidebarItem[];
  type?: SidebarType;
};

export type SidebarMenuComponent = FC<PropsWithChildren<SidebarMenuProps>> & SidebarMenuComposition;

export const SidebarMenu: SidebarMenuComponent = (props) => {
  const {
    className,
    title,
    collapsible,
    value: selectedItem,
    onSelect,
    actions = [],
    children,
    items,
    type = SidebarType.Default,
  } = props;
  const selectedKeys = useMemo(() => [String(selectedItem.value)], [selectedItem]);
  const [expanded, setExpanded] = useState(true);

  const selectHandler = useCallback(
    ({ key }: { key: React.ReactText }) => onSelect?.(String(key)),
    [onSelect],
  );

  const menuItems: UiCollapseProps['items'] = [
    {
      label: title,
      children,
    },
  ];

  const wrapContent = (content: React.ReactNode) => {
    if (collapsible) {
      return (
        <UiCollapse
          ghost
          onChange={() => setExpanded(!expanded)}
          expandIconPosition="right"
          activeKey={expanded ? 'panel' : undefined}
          items={menuItems}
        />
      );
    }

    return (
      <>
        {title && (
          <div className={styles.sidebarMenu__heading}>
            <UiTypography.Title level={4} className={styles.sidebarMenu__title}>
              {title}
            </UiTypography.Title>
            {actions.length > 0 && <ActionsDropdown items={actions} />}
          </div>
        )}
        {content}
      </>
    );
  };

  const subMenuItems = useMemo<UiMenuItemType[]>(() => {
    return (items || []).map((item) => ({
      label: <SidebarMenuItem item={item} type={type} />,
      key: String(item.value),
    }));
  }, [items, type]);

  return (
    <div className={classnames(styles.sidebarMenu, className)}>
      {wrapContent(
        <>
          <UiMenu
            className={styles.sidebarMenu__menu}
            onSelect={selectHandler}
            selectedKeys={selectedKeys}
            items={subMenuItems}
          />
          {children}
        </>,
      )}
    </div>
  );
};

SidebarMenu.Item = SidebarMenuItem;
