import {
  notification,
  UiFlex,
  UiIcon,
  UiTooltip,
  UiTypography,
  useModalBase,
  usePaddingStyle,
  useSpace,
} from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { closeGlobalModal, GlobalModalNames, openGlobalModal } from '@vkph/common/store';
import { deleteListRowGroupEffect, ungroupListRowGroupEffect } from '@vkph/common/store/lists';
import { ListGroup, ListRowGroupType } from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import DeleteSvg from '@vkph/ui/svg/delete.svg';
import EditSvg from '@vkph/ui/svg/edit.svg';
import FolderAddSvg from '@vkph/ui/svg/folder-add.svg';
import FolderSvg from '@vkph/ui/svg/folder.svg';

import { ActionsDropdown } from '../../../actions-dropdown';
import { DynamicTableCellProps } from '../DynamicTableCell';
import { DynamicTableCellContentProps } from '../types';
import { getGroupNameByType } from '../utils';

interface Props
  extends DynamicTableCellContentProps,
    Pick<DynamicTableCellProps, 'rowStorage' | 'toggleIsBlockedTable'> {
  group: ListGroup;
}

export const GroupCell: FC<Props> = (props) => {
  const { group, fieldType, value, containerRef, rowStorage, toggleIsBlockedTable, fieldOptions } = props;
  const { id, listId, name, groupType } = group || {};
  const { warning } = useModalBase();
  const { spaceXL, spaceL, spaceXS } = useSpace();
  const paddingStyle = usePaddingStyle([spaceXL, spaceL]);

  const openGroupRowsEditor = () => {
    toggleIsBlockedTable?.(false);

    openGlobalModal(GlobalModalNames.GroupRowsEditor, {
      listId,
      group,
      onSuccess: () => {
        toggleIsBlockedTable?.(true);
        rowStorage.refetchWithLastParams();
      },
      onClose: () => {
        toggleIsBlockedTable?.(true);
        closeGlobalModal(GlobalModalNames.GroupRowsEditor);
      },
    });
  };

  const ungroupRows = () => {
    ungroupListRowGroupEffect({ listId, groupId: id })
      .then(() => {
        rowStorage.refetchWithLastParams();
        notification.success({ message: 'Строки успешно разгруппированы' });
      })
      .catch((e) => {
        notification.error({ message: getErrorResponseMessage(e, 'Не удалось разгруппировать строки') });
      });
  };

  const deleteGroup = () => {
    warning({
      title: 'Вы уверены, что хотите удалить эту группу строк?',
      content: 'Все данные группы будут потеряны без возможности восстановления',
      okText: 'Удалить группу',
      cancelText: 'Отменить удаление',
      centered: true,
      getContainer: () => {
        return containerRef?.current || document.body;
      },
      onOk: () => {
        deleteListRowGroupEffect({ listId, groupId: id })
          .then(() => {
            rowStorage.refetchWithLastParams();
            notification.success({ message: 'Сгруппированные строки успешно удалены' });
          })
          .catch((e) => {
            notification.error({ message: getErrorResponseMessage(e, 'Не удалось удалить группу строк') });
          });
      },
    });
  };

  const actionItems =
    groupType === ListRowGroupType.AutoColumn
      ? []
      : [
          {
            key: 'ungroup',
            label: 'Разгруппировать',
            icon: FolderAddSvg,
            onClick: ungroupRows,
          },
          {
            key: 'update',
            label: 'Переименовать',
            icon: EditSvg,
            onClick: openGroupRowsEditor,
          },
          {
            key: 'delete',
            label: 'Удалить',
            icon: DeleteSvg,
            onClick: deleteGroup,
          },
        ];

  const groupName = useMemo(() => {
    return groupType === ListRowGroupType.AutoColumn
      ? getGroupNameByType({ value, fieldType, fieldOptions, defaultName: name })
      : name;
  }, [name, value, fieldType, groupType, fieldOptions, getGroupNameByType]);

  return (
    <UiTooltip placement="top" title={groupName}>
      <UiFlex gap={spaceXS} style={paddingStyle} align="center">
        <UiIcon color={variables.themePalette.colorIcon} component={FolderSvg} width={20} height={20} />
        <UiTypography.Text>{groupName}</UiTypography.Text>
        {actionItems.length > 0 && (
          <ActionsDropdown
            trigger={['click']}
            getPopupContainer={() => containerRef?.current || document.body}
            items={actionItems}
          />
        )}
      </UiFlex>
    </UiTooltip>
  );
};
