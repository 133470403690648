import { Rule } from '@vkph/ui';

import { ListColumnFieldType } from '@vkph/common/types/models';
import {
  MUST_BE_FILLED_MESSAGE,
  regexpPatterns,
  requiredDateOrTimeRule,
  requiredRule,
  requiredRuleSelect,
  validURLWithProtocolRule,
} from '@vkph/common/utils';

export const DYNAMIC_TABLE_CELL_COMMON_RULE_BOOK: Record<ListColumnFieldType, Rule[]> = {
  [ListColumnFieldType.Boolean]: [],
  [ListColumnFieldType.Numeric]: [
    {
      message: 'Пожалуйста, введите числовое значение',
      pattern: regexpPatterns.number,
    },
    requiredRule,
  ],
  [ListColumnFieldType.Text]: [requiredRule],
  [ListColumnFieldType.Hyperlink]: [validURLWithProtocolRule],
  [ListColumnFieldType.User]: [
    {
      type: 'object',
      required: true,
      whitespace: true,
      message: MUST_BE_FILLED_MESSAGE,
    },
  ],
  [ListColumnFieldType.File]: [
    {
      type: 'object',
      required: true,
      whitespace: true,
      message: MUST_BE_FILLED_MESSAGE,
    },
  ],
  [ListColumnFieldType.Dictionary]: [
    {
      type: 'array',
      required: true,
      whitespace: true,
      message: MUST_BE_FILLED_MESSAGE,
    },
  ],
  [ListColumnFieldType.Enum]: [requiredRuleSelect],
  [ListColumnFieldType.Datetime]: [requiredDateOrTimeRule],
};
