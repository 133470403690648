import {
  UiButton,
  UiCol,
  UiFile,
  UiFileDirection,
  UiFileProps,
  UiIcon,
  UiImage,
  UiRow,
  UiSpace,
  UiVideo,
  useBreakpoint,
} from '@vkph/ui';
import React, { FC, ReactNode, useMemo } from 'react';

import { FileStorageEntryType } from '@vkph/common/types/models';
import { FileUploadAccepts, getSupportedMediaFile } from '@vkph/common/utils';
import { useToggle } from '@vkph/ui/hooks';
import MeatballSvg from '@vkph/ui/svg/meatball.svg';

import { ActionsDropdown } from '../../../../actions-dropdown';
import { FileStorageListItemFileProps } from '../FileStorageListItemFile';
import { FileStorageListItemFileActions } from '../actions/FileStorageListItemFileActions';

type Props = Pick<FileStorageListItemFileProps, 'file' | 'getFileActions'> & UiFileProps;

export const FileStorageListItemFileColumn: FC<Props> = (props) => {
  const { file, getFileActions, ...fileProps } = props;
  const isFolder = file.type === FileStorageEntryType.Folder;
  const actions = getFileActions(file);
  const isSupportedImageFile = getSupportedMediaFile(FileUploadAccepts.ImageAll, file.additional);
  const isSupportedVideoFile = getSupportedMediaFile(FileUploadAccepts.VideoAll, file.additional);
  const { lg: isLayoutLarge } = useBreakpoint();
  const [isMenuOpen, toggleMenuOpen] = useToggle([false, true]);
  const thumbnail = useMemo<ReactNode>(() => {
    if (isSupportedVideoFile) {
      return <UiVideo src={file.file} width={162} height={100} />;
    }

    if (isSupportedImageFile) {
      return (
        <UiImage
          placeholderSize={100}
          style={{ objectFit: 'contain' }}
          width={162}
          height={100}
          src={file.file}
        />
      );
    }

    return null;
  }, [file]);

  return (
    <UiCol span={24} style={{ padding: '24px 0 6px 0' }}>
      <UiRow justify="center">
        <UiSpace size={6} direction="vertical" align="center">
          <UiFile direction={UiFileDirection.Vertical} thumbnail={thumbnail} {...fileProps} size="small" />
          {isLayoutLarge && actions?.length > 0 && <ActionsDropdown items={actions} />}
          {!isLayoutLarge && (
            <UiButton
              size="small"
              type="link-secondary"
              onClick={() => {
                toggleMenuOpen();
              }}
              icon={<UiIcon width={20} height={20} component={MeatballSvg} />}
            />
          )}
        </UiSpace>
        {!isLayoutLarge && (
          <FileStorageListItemFileActions
            title={`Действия с ${isFolder ? 'папкой' : 'файлом'}`}
            actions={actions}
            open={isMenuOpen}
            onClose={() => toggleMenuOpen()}
          />
        )}
      </UiRow>
    </UiCol>
  );
};
