import {
  UiCell,
  UiFlex,
  UiForm,
  UiModal,
  UiModalTypes,
  UiTruncateMarkup,
  usePaddingStyle,
  useSpace,
  useToggle,
} from '@vkph/ui';
import React, { FC, useEffect } from 'react';

import { UserCellValue } from '@vkph/common/types/models';

import { CellInput } from '../../../cell-input';
import { UserAvatar } from '../../../user-avatar';
import { UserLinkCell } from '../../../user-link-cell';
import { DynamicTableCellContentProps } from '../types';
import { UserCellModalForm } from './user-cell-form/UserCellForm';

type Props = DynamicTableCellContentProps<UserCellValue | undefined>;

export const UserCell: FC<Props> = (props) => {
  const { inputSuffix, value, cellNamePath, onCellBlur, readOnly, fieldOptions, rules } = props;
  const [isModalOpen, toggleModalOpen] = useToggle([false, true]);
  const { spaceL, spaceXL } = useSpace();
  const form = UiForm.useFormInstance();
  const user = UiForm.useWatch<UserCellValue>(cellNamePath, form);
  const padding = usePaddingStyle([spaceXL, spaceL]);

  const onSave = (formValue: UserCellValue) => {
    const val = {
      id: formValue.id,
      name: formValue.name,
      avatar: formValue.avatar,
    };

    form.setFieldValue(cellNamePath, val);

    return onCellBlur?.(val);
  };

  useEffect(() => {
    form.setFieldValue(cellNamePath, value);
  }, [value]);

  if (readOnly) {
    return <UiFlex style={padding}>{value && <UserLinkCell size={spaceL} lines={1} user={value} />}</UiFlex>;
  }

  return (
    <>
      <UiModal type={UiModalTypes.Medium} onClose={toggleModalOpen} isOpen={isModalOpen}>
        <UserCellModalForm
          value={value}
          onSave={onSave}
          fieldOptions={fieldOptions}
          onModalClose={() => toggleModalOpen(false)}
        />
      </UiModal>

      <UiForm.Item name={cellNamePath} rules={rules}>
        <CellInput.Button onClick={() => toggleModalOpen(true)} isEmpty={!user} suffix={inputSuffix}>
          {!user && 'Добавить пользователя'}
          {user && (
            <UiCell
              title={
                <UiTruncateMarkup lines={1} truncate>
                  {user.name}
                </UiTruncateMarkup>
              }
              avatar={<UserAvatar size={spaceL} src={user.avatar} />}
            />
          )}
        </CellInput.Button>
      </UiForm.Item>
    </>
  );
};
