import { UiCheckbox, UiForm, usePaddingStyle, useSpace } from '@vkph/ui';
import React, { FC, useEffect } from 'react';

import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<undefined | boolean>;

export const BooleanCell: FC<Props> = (props) => {
  const { value, cellNamePath, fieldOptions, readOnly, onCellBlur } = props;
  const form = UiForm.useFormInstance();
  const formValue = UiForm.useWatch<boolean | undefined>(cellNamePath, form);
  const { spaceXL } = useSpace();
  const style = usePaddingStyle([spaceXL]);

  const onClick = () => {
    const newVal = !form.getFieldValue(cellNamePath);

    form.setFieldValue(cellNamePath, newVal);
    onCellBlur?.(newVal);
  };

  useEffect(() => {
    form.setFieldValue(cellNamePath, value || false);
  }, [value]);

  return (
    <>
      <UiForm.Item name={cellNamePath} hidden />
      <UiCheckbox style={style} disabled={readOnly} checked={formValue} onClick={onClick}>
        {formValue ? fieldOptions.trueValueName : fieldOptions.falseValueName}
      </UiCheckbox>
    </>
  );
};
