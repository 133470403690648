import { Editor } from '@ckeditor/ckeditor5-core';
import { FileLoader, FileRepository, UploadAdapter, UploadResponse } from '@ckeditor/ckeditor5-upload';
import axios, { Canceler } from 'axios';

import { endpoints } from '@vkph/common/endpoints';
import { PreloadedFileModel } from '@vkph/common/types/models';
import { api, AxiosErrorResponseMessage, getErrorResponseMessage } from '@vkph/common/utils';
import { notification } from '@vkph/ui';

class FileUploadAdapter implements UploadAdapter {
  private loader: FileLoader;
  private cancel: Canceler | null = null;

  constructor(loader: FileLoader) {
    this.loader = loader;
  }

  async upload(): Promise<UploadResponse> {
    const file = await this.loader.file;

    if (!file) {
      this.onLoadingAbort();
      return Promise.reject();
    }

    return new Promise<UploadResponse>((resolve) => {
      this.uploadFileToServer(file)
        .then((uploadedFile) => resolve(uploadedFile))
        .catch((e) => {
          this.onLoadingAbort(e);
          return Promise.reject();
        });
    });
  }

  async uploadFileToServer(file: File) {
    try {
      const formData = new window.FormData();

      formData.append('file', file);

      const source = axios.CancelToken.source();

      this.cancel = source.cancel;

      const { data: uploadedFile } = await api.post<PreloadedFileModel>({
        url: endpoints.filestorage.fileUpload(),
        data: formData,
        cancelToken: source.token,
        onUploadProgress: (progressEvent) => {
          this.loader.uploadTotal = progressEvent.total || null;
          this.loader.uploaded = progressEvent.loaded;
        },
      });

      return {
        urls: {
          default: uploadedFile.fileUrl,
        },
        fileId: uploadedFile.storageObject,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  onLoadingAbort = (error?: AxiosErrorResponseMessage) => {
    this.loader.abort();

    let message = 'Не удалось загрузить файл';

    if (error) {
      message = getErrorResponseMessage(error, message);
    }

    notification.error({ message });
  };

  abort() {
    if (this.cancel !== null) {
      this.cancel();
    }
  }
}

export function UploadAdapterPlugin(editor: Editor) {
  // eslint-disable-next-line no-param-reassign
  editor.plugins.get(FileRepository).createUploadAdapter = (loader: FileLoader) => {
    return new FileUploadAdapter(loader);
  };
}
