import { UiSpace, UiSpaceProps, UiTypography } from '@vkph/ui';
import isString from 'lodash/isString';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { AchievementCoins } from './coins/AchievementCoins';
import { AchievementIcon } from './icon/AchievementIcon';
import { AchievementPlaceholder } from './placeholder/AchievementPlaceholder';

export interface AchievementProps extends Omit<UiSpaceProps, 'align'> {
  title: string;
  paragraph?: ReactNode;
  align?: 'start' | 'center';
}

type AchievementComposition = {
  Coins: typeof AchievementCoins;
  Icon: typeof AchievementIcon;
  Placeholder: typeof AchievementPlaceholder;
};

export const Achievement: FC<PropsWithChildren<AchievementProps>> & AchievementComposition = (props) => {
  const { children, title, paragraph, ...spaceProps } = props;
  const { size = 20, direction, align = 'start' } = spaceProps;
  const isParagraphString = isString(paragraph);

  return (
    <UiSpace style={{ padding: 20 }} full {...spaceProps} size={size} direction={direction} align={align}>
      {children}

      <UiSpace size={0} direction="vertical" align={align}>
        <UiTypography.Title level={3}>{title}</UiTypography.Title>

        {isParagraphString && (
          <UiTypography.Paragraph style={{ textAlign: align === 'start' ? 'left' : align }}>
            {paragraph}
          </UiTypography.Paragraph>
        )}
        {!isParagraphString && paragraph && (
          <UiSpace size={8} direction="vertical" align={align}>
            {paragraph}
          </UiSpace>
        )}
      </UiSpace>
    </UiSpace>
  );
};

Achievement.Coins = AchievementCoins;
Achievement.Icon = AchievementIcon;
Achievement.Placeholder = AchievementPlaceholder;
