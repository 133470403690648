import {
  FileCellValue,
  ListCellFileModel,
  ListCellModelValues,
  ListCellValues,
  ListColumnFieldType,
  UserCellValue,
  UserModel,
} from '@vkph/common/types/models';

export const isBooleanCellType = (
  value: ListCellModelValues,
  fieldType: ListColumnFieldType,
): value is [boolean, string] => {
  return fieldType === ListColumnFieldType.Boolean && Array.isArray(value) && typeof value[0] === 'boolean';
};

export const isUserCellType = (val: ListCellValues, fieldType: ListColumnFieldType): val is UserCellValue => {
  return fieldType === ListColumnFieldType.User && typeof val === 'object' && 'avatar' in val;
};

export const isUserModelType = (
  val: ListCellModelValues,
  fieldType: ListColumnFieldType,
): val is UserModel => {
  return fieldType === ListColumnFieldType.User && typeof val === 'object' && 'smallAvatar' in val;
};

export const isDictionaryType = <T extends ListCellModelValues | ListCellValues>(
  value: ListCellModelValues | ListCellValues,
  fieldType: ListColumnFieldType,
): value is T => {
  return fieldType === ListColumnFieldType.Dictionary && Array.isArray(value);
};

export const isFileType = <T extends ListCellFileModel | FileCellValue>(
  value: ListCellModelValues,
  fieldType: ListColumnFieldType,
): value is T => {
  return fieldType === ListColumnFieldType.File && typeof value === 'object' && 'file' in value;
};
