import { UiAvatar, UiButton, UiCol, UiIcon, UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React from 'react';
import { Link } from 'react-router-dom';

import AboutSvg from '@vkph/ui/svg/about.svg';
import ContactMailSvg from '@vkph/ui/svg/contact-mail.svg';

import styles from './LayoutContentHeader.scss';
import { LayoutContentHeaderSkeleton } from './skeleton/LayoutContentHeaderSkeleton';

export type LayoutContentHeaderSize = 'small' | 'medium';

export interface LayoutContentHeaderProps {
  size: LayoutContentHeaderSize;
  onClickInfo?: () => void;
  className?: string;
  name: string;
  title: string;
  to?: string;
}

export interface LayoutContentHeader extends React.FC<LayoutContentHeaderProps> {
  Skeleton: typeof LayoutContentHeaderSkeleton;
}

export const LayoutContentHeader: LayoutContentHeader = (props) => {
  const { size, onClickInfo, className, name, title, to } = props;
  const isHeaderSize = (headerSize: LayoutContentHeaderSize) => size === headerSize;

  return (
    <UiSpace size={isHeaderSize('small') ? 12 : 20} className={className}>
      <UiAvatar size={64} icon={<UiIcon component={ContactMailSvg} height={40} width={40} />} />
      <UiCol className={styles.layoutContentHeader__name}>
        {to && <Link to={to}>{name}</Link>}
        {!to && <UiTypography.Text>{name}</UiTypography.Text>}
        <UiTypography.Title
          level={isHeaderSize('small') ? 4 : 2}
          className={styles.layoutContentHeader__title}
        >
          <UiTruncateMarkup truncate>{title}</UiTruncateMarkup>
          {isHeaderSize('small') && (
            <UiButton
              type="link-secondary"
              icon={<UiIcon component={AboutSvg} width={20} height={20} />}
              onClick={onClickInfo}
            />
          )}
        </UiTypography.Title>
      </UiCol>
    </UiSpace>
  );
};

LayoutContentHeader.Skeleton = LayoutContentHeaderSkeleton;
