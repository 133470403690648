import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { GetCalendarSelectedStorage } from '@vkph/common/store/calendar';
import { UiButton, UiFlex, UiIcon } from '@vkph/ui';
import ArrowExpandSvg from '@vkph/ui/svg/arrow-expand.svg';

import styles from './ColumnSeparator.scss';

type Props = {
  isSidebar?: boolean;
  selectedStorage: GetCalendarSelectedStorage;
};

export const ColumnSeparator: FC<Props> = (props) => {
  const { isSidebar, selectedStorage } = props;

  if (!isSidebar) {
    return <div className={styles.columnSeparator} />;
  }

  const { isHiddenEarlyTimeSectionStore, isHiddenEarlyTimeSectionUpdated } = selectedStorage;
  const isHiddenEarlyTimeSection = useStore(isHiddenEarlyTimeSectionStore);

  return (
    <UiFlex className={styles.columnSeparator} align="center" justify="center">
      <UiButton
        block
        type="link-secondary"
        onClick={() => isHiddenEarlyTimeSectionUpdated()}
        icon={<UiIcon component={ArrowExpandSvg} width={20} height={20} />}
        className={classNames({
          [styles.columnSeparator_rotateSvg]: !isHiddenEarlyTimeSection,
        })}
      />
    </UiFlex>
  );
};
